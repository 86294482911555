import {calculateDiscountAmount, getShortDate} from "./commonHelper";
import {getEarningCSVDataLink} from "./earningCSVDownloadHelper";
import {sortTableByMonth} from "../filter";

export const getEarningsTable = (monthsBasedData, discount, accountData) => {
    let tableRowsArr = [];
    for (const month in monthsBasedData) {
        let tableCol = [];
        let dateObj = new Date(month);
        tableCol.push(getShortDate(dateObj));
        let totalNumberOfActiveAcc = monthsBasedData[month].billedAccountsCount;
        tableCol.push(totalNumberOfActiveAcc);
        let total = monthsBasedData[month].totalBillingAmount.toFixed(2);
        tableCol.push(total);
        let discountAmount = calculateDiscountAmount(total, discount);
        tableCol.push(discountAmount);
        let detailsBtn = getEarningCSVDataLink("details_" + month, monthsBasedData[month].billingInfos, accountData.getAccountsObj(), discount);
        tableCol.push(detailsBtn);
        tableRowsArr.push(tableCol);
    }
    return tableRowsArr.sort(sortTableByMonth);
}
