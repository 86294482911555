import React from "react";
import DashboardInfo from "./dashboardInfo";
import AccountsTableSection from "./accountsTableSection";
import ComponentHeader from "../CommonComponents/componentHeader";

const DASHBOARD_COMPONENT = "DASHBOARD_COMPONENT"

const DashboardWrapper = () => {
    return (
        <>
            <ComponentHeader title={"Accounts"}/>
            <DashboardInfo/>
            <AccountsTableSection/>
        </>
    )
}

export default DashboardWrapper;
export {DASHBOARD_COMPONENT}

