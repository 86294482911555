import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ANALYTICS_TIME_FRAME} from "../../Reducers/analyticsTimeFrameReducer";

const TimeFrameSingleBtn = ({first, last, value, text, mobileView}) => {
    const selectedTimeFrame = useSelector(state => state.analyticsTimeFrame);
    const dispatch = useDispatch();

    const handleClick = (ev) => {
        dispatch({
            type: ANALYTICS_TIME_FRAME,
            payload: value
        })
    }

    return (
        <button type="button"
                className={`btn sd-time-btn ${!first ? 'sd-border-start' : ''} ${!last ? 'sd-border-end' : ''} ${selectedTimeFrame === value ? 'bg-white' : ''} ${mobileView?'time-selector-mobile-view ':''}`}
                onClick={handleClick}>{text}</button>
    )
}

export default TimeFrameSingleBtn;
