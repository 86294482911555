import {constructEnterpriseAccounts} from "../Factory/factory";
import {findEnterpriseAccounts} from "../Util/queries";

const PARTNER_ACCOUNTS = "PARTNER_ACCOUNTS";

export const getAccountsDetails = () =>
    async (dispatch) => {
        try {
            let response = await findEnterpriseAccounts();
            if (response.data) {
                let enterpriseAccountsObj = constructEnterpriseAccounts(response.data);
                dispatch({
                    type: PARTNER_ACCOUNTS,
                    payload: enterpriseAccountsObj
                })
            } else {
                dispatch({
                    type: PARTNER_ACCOUNTS,
                    payload: null
                })
            }
        } catch (e) {
            console.error(e);
            dispatch({
                type: PARTNER_ACCOUNTS,
                payload: null
            })
        }
    }

export {PARTNER_ACCOUNTS}
