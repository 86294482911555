import React, {useEffect} from "react";
import "../../Stylesheets/index.css";
import LeftNavbar from "./leftNavbar";
import {useDispatch, useSelector} from "react-redux";
import EnterprisePortalHeaderWithBtn from "./enterprisePortalHeaderWithBtn";
import DashboardWrapper, {DASHBOARD_COMPONENT} from "../Dashboard/dashboardWrapper";
import BillingPageWrapper, {BILLING_COMPONENT} from "../Billing/billingPageWrapper";
import EarningPageWrapper, {EARNING_COMPONENT} from "../Earning/earningPageWrapper";
import ProfilePageWrapper, {PROFILE_COMPONENT} from "../Profile/profilePageWrapper";
import PaymentsWrapper, {PAYMENT_COMPONENT} from "../Payments/paymentsWrapper";
import {getAccountsDetails} from "../../Actions/enterpriseAccountsAction";
import {getPartnerDetails} from "../../Actions/enterpriseUserDetailsAction";
import {getPartnerBillingDetails} from "../../Actions/enterpriseBillingDetailsAction";
import {getPartnerInvoices} from "../../Actions/partnerInvoiceAction";
import {GetPartnerCardDetails} from "../../Actions/partnerCardInfoDetailsAction";
import PayoutsWrapper, {PAYOUTS_COMPONENT} from "../Payouts/payoutsWrapper";
import ThirdPartyBillingWrapper, {THIRD_PARTY_COMPONENT} from "../ThirdPartyBilling/thirdPartyBillingWrapper";
import getPartnerThirdPartyBillingDetails from "../../Actions/partnerThirdPartyBillingDetailsAction";
import AnalyticsWrapper, {ANALYTICS_COMPONENT} from "../Analytics/analyticsWrapper";
import PaymentSetupWrapper, {PAYMENT_SETUP} from "../PaymentSetup/paymentSetupWrapper";
import {getACHBankInfo} from "../../Actions/achBankInfoAction";

const EnterprisePortalWrapper = () => {
    const sideBarActive = useSelector(state => state.leftSideBarActive);
    const selectedComponent = useSelector(state => state.selectedComponent);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPartnerDetails());
        dispatch(getAccountsDetails());
        dispatch(getPartnerBillingDetails());
        dispatch(getPartnerInvoices());
        dispatch(GetPartnerCardDetails());
        dispatch(getPartnerThirdPartyBillingDetails());
        dispatch(getACHBankInfo());
    }, [])

    const getSelectedComponent = () => {
        switch (selectedComponent.component) {
            case DASHBOARD_COMPONENT:
                return <DashboardWrapper/>
            case BILLING_COMPONENT:
                return <BillingPageWrapper/>
            case EARNING_COMPONENT:
                return <EarningPageWrapper/>
            case THIRD_PARTY_COMPONENT:
                return <ThirdPartyBillingWrapper/>
            case PAYMENT_COMPONENT:
                return <PaymentsWrapper/>
            case PROFILE_COMPONENT:
                return <ProfilePageWrapper/>
            case PAYOUTS_COMPONENT:
                return <PayoutsWrapper/>
            case ANALYTICS_COMPONENT:
                return <AnalyticsWrapper/>
            case PAYMENT_SETUP:
                return <PaymentSetupWrapper/>
            default:
                return <DashboardWrapper/>
        }
    }

    return (
        <>
            <LeftNavbar/>
            <div className={`container-fluid overflow-auto mainContainer ${sideBarActive ? "activeCont" : ""}`}>
                <EnterprisePortalHeaderWithBtn/>
                <div className="content-body">
                    {getSelectedComponent()}
                </div>
            </div>
        </>
    )
}

export default EnterprisePortalWrapper;
