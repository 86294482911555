import {findMagicLink} from "../Util/queries";
import {constructMagicLink} from "../Factory/factory";
import {MAGIC_LINK} from "../Reducers/magicLinkReducer";

const getMagicLink = (adminId, companyId) => async dispatch => {
    try {
        let response = await findMagicLink({adminId, companyId});
        if (response.data) {
            let magicLinkObj = constructMagicLink(response.data.magicLink);
            dispatch({
                type: MAGIC_LINK,
                payload: magicLinkObj
            });
        } else {
            dispatch({
                type: MAGIC_LINK,
                payload: null
            });
        }
    } catch (e) {
        console.error(e);
        dispatch({
            type: MAGIC_LINK,
            payload: null
        });
    }
}

export default getMagicLink;
