export const getInvoicesTable = (invoiceDataArr = []) => {
    return invoiceDataArr.map((data) => {
        let chargeDate = new Date(data.chargeTime).toDateString();
        let status = "Pending";
        if (data.isSuccessful) status = "Paid";
        let tableCol = [status, chargeDate, data.totalAmountPaid, getInvoiceViewBtn(data.invoiceUrl)]
        return tableCol;
    })
}

const getInvoiceViewBtn = (link) => {
    return <a className="invoiceLink" href={link} target="_blank" title={"Click to view Detailed Invoice"}>view</a>
}
