import React, {useEffect, useRef} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import "../../Stylesheets/stripeCard.css";
import {AddPartnerCard} from "../../Actions/partnerCardAddAction";
import {useDispatch, useSelector} from "react-redux";
import {UpdatePartnerCard} from "../../Actions/partnerCardUpdateAction";
import {CARD_UPDATE_STATES, PARTNER_CREDIT_UPDATE_CARD_STATE} from "../Payments/creditCardInfoSection";
import {GetPartnerCardDetails} from "../../Actions/partnerCardInfoDetailsAction";

const cardStyle = {
    base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: 'Helvetica Neue',
        '::placeholder': {
            color: '#CFD7E0',
        },
    },
}
let cardElementRef;

export const StripeCard = ({show, isUpdate}) => {
    const stripe = useStripe();
    const elements = useElements();
    const errorRef = useRef(null);
    const errorDetailsRef = useRef(null);
    const submitBtnRef = useRef(null);
    const successRef = useRef(null);
    const cardState = useSelector(state => state.partnerCreditCardUpdateState);
    const partnerDetails = useSelector(state => state.partnerDetails);
    const dispatch = useDispatch();
    let submitBtnText = "Add Card";
    if (isUpdate) submitBtnText = "Update Card";

    useEffect(() => {
        errorRef.current.style.display = "none";
        successRef.current.style.display = "none";
        errorDetailsRef.current.innerHTML = "";
        errorDetailsRef.current.style.display = "none";
        if (!cardElementRef?._destroyed) {
            cardElementRef?.clear();
        }
    }, [show]);

    useEffect(() => {
        if (cardState === CARD_UPDATE_STATES.failed) {
            showError("Please Check if the information is correct");
            if (submitBtnRef.current !== null) submitBtnRef.current.disable = false;
        } else if (cardState === CARD_UPDATE_STATES.success) {
            showSuccess();
            setTimeout(() => {
                document.getElementById("partnerCreditCardModalCloseBtn").click();
                dispatch(GetPartnerCardDetails());
            }, 2000);

        } else if (cardState === CARD_UPDATE_STATES.pending) {
            if (submitBtnRef.current !== null) submitBtnRef.current.disable = true;
        } else {
            successRef.current.style.display = "none";
            errorRef.current.style.display = "none";
            errorDetailsRef.current.style.display = "none";
            if (submitBtnRef.current !== null) submitBtnRef.current.disable = false;
        }
    }, [cardState])

    const showError = (errMsg) => {
        errorRef.current.style.display = "block";
        successRef.current.style.display = "none";
        errorDetailsRef.current.innerHTML = errMsg;
        errorDetailsRef.current.style.display = "block";
    }

    const showSuccess = () => {
        successRef.current.style.display = "block";
        errorRef.current.style.display = "none";
        errorDetailsRef.current.style.display = "none";
    }

    const handleSubmit = (ev) => {
        ev.preventDefault();

        const cardElement = elements.getElement("card");
        dispatch({
            type: PARTNER_CREDIT_UPDATE_CARD_STATE,
            payload: CARD_UPDATE_STATES.pending
        })
        stripe.createToken(cardElement)
            .then((payload) => {
                if (payload.error) {
                    showError(payload.error.message);
                    dispatch({
                        type: PARTNER_CREDIT_UPDATE_CARD_STATE,
                        payload: CARD_UPDATE_STATES.failed
                    })
                } else {
                    if (isUpdate) dispatch(UpdatePartnerCard(partnerDetails.email, payload.token["id"]));
                    else dispatch(AddPartnerCard(partnerDetails.email, payload.token["id"]));
                }
            });
    };

    return (
        <form onSubmit={handleSubmit} id="creditCardAddFrom">
            <div className="group border shadow-none">
                <label>
                    <span>Name</span>
                    <input id="card_name" className="field" placeholder="John Doe (Optional)"/>
                </label>
            </div>
            <div className="group border shadow-none">
                <label>
                    <span>Card</span>
                    <CardElement onReady={(c) => cardElementRef = c} className="field stripeCard" options={{
                        style: cardStyle
                    }}/>
                </label>

            </div>
            <button type="submit" className="btn" ref={submitBtnRef}>
                {cardState === CARD_UPDATE_STATES.pending &&
                <span className="spinner-border spinner-border-sm mb-1 me-2" role="status" aria-hidden="true"/>}
                {submitBtnText}</button>
            <div className="outcome">
                <div className="error" ref={errorRef}>Sorry! Could not add your card</div>
                <div className="success" ref={successRef}>
                    Success! Your Card is added.
                </div>
                <div className="error" ref={errorDetailsRef}></div>
            </div>
        </form>

    );
}

export default StripeCard;
