import {LEFT_SIDE_BAR} from "../Components/EnterprisePortal/leftNavbar";

export default (state = false, action) => {
    switch (action.type) {
        case LEFT_SIDE_BAR:
            return action.payload;
        default:
            return state;
    }
}
