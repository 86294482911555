import React from "react";

const LoadingSpinner = () => {
    return (
        <div className='position-absolute top-50 start-50'>
            <div className='px-4 py-3 bg-white rounded border shadow-sm'>
                <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default LoadingSpinner;
