export const MAGIC_LINK = "MAGIC_LINK";

export default (state = null, action) => {
    switch (action.type) {
        case MAGIC_LINK:
            return action.payload;
        default:
            return state;
    }
}
