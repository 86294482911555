import {calculateDiscountAmount} from "./TableHelpers/commonHelper";
import {calculateAmountAfterDiscount} from "./filter";

export const getTotalsForTheMonth = monthBasedDataArr => {
    let totalOrders = 0;
    let totalPaidOrders = 0;
    let totalBillingAmount = 0;
    let billedAccountsCount = 0;
    monthBasedDataArr.forEach(billingData => {
        totalOrders += billingData.numberOfOrders;
        totalPaidOrders += billingData.numberOfPaidOrders;
        totalBillingAmount += billingData.billableAmount;
    })
    billedAccountsCount = monthBasedDataArr.length;
    return {
        totalOrders, totalPaidOrders, totalBillingAmount, billedAccountsCount
    }
}

export const getTotalThirdPartyOrdersByWeek = weekBasedDataArr => {
    let total = 0;
    let totalPaid = 0;
    let outstandingAmount = 0;
    let billedAccountsCount = 0;

    weekBasedDataArr.forEach((data) => {
        total += data.totalAmount;
        totalPaid += data.paidAmount;
        outstandingAmount += data.unpaidAmount;
    })
    total = total.toFixed(2);
    totalPaid = totalPaid.toFixed(2);
    outstandingAmount = outstandingAmount.toFixed(2);
    billedAccountsCount = weekBasedDataArr.length;
    return {
        total, totalPaid, outstandingAmount, billedAccountsCount
    }
}

export const getTotalDueAndSavingForCurrentMonth = (billingDetails, discount) => {
    let currentMonth = new Date();
    let targetDate;
    for (let date in billingDetails) {
        targetDate = new Date(date);
        if (currentMonth.getMonth() === targetDate.getMonth()) {
            let afterDiscountAmount = calculateAmountAfterDiscount(billingDetails[date].totalBillingAmount, discount);
            let discountedAmount = calculateDiscountAmount(afterDiscountAmount, discount);
            return {afterDiscountAmount, discountedAmount}
        }
    }
    return {afterDiscountAmount: "0", discountedAmount: "0"}
}

export const getTotalSavingForCurrentYear = (billingDetails, discount) => {
    let currentMonth = new Date();
    let targetDate;
    let totalAmount = 0;
    for (let date in billingDetails) {
        targetDate = new Date(date);
        if (currentMonth.getFullYear() === targetDate.getFullYear()) {
            totalAmount += billingDetails[date].totalBillingAmount;
        }
    }
    return calculateDiscountAmount(totalAmount, discount);
}

const BRANDED_PREMIUM = "BRANDED_PREMIUM";
const BRANDED_PREMIUM_PLUS = "BRANDED_PREMIUM_PLUS";
const BUSINESS_ADVANCED = "BUSINESS_ADVANCED";


export const formatCurrentPlan = (currentPlan) => {
    if (currentPlan === BRANDED_PREMIUM || currentPlan === BRANDED_PREMIUM_PLUS) return "Branded Premium";
    if (currentPlan === BUSINESS_ADVANCED) return "Business Advanced";

    let ind = currentPlan.indexOf("_");
    let planName = "";
    let extra = "";
    if (ind !== -1) {
        planName = currentPlan.substr(0, ind);
        extra = currentPlan.substr(ind + 1);
    } else planName = currentPlan;
    planName = onlyFirstLetterCapital(planName)
    if (extra) {
        extra = extra.toLowerCase();
        extra = extra.replaceAll("_", " ");
        planName += " (" + extra + ")"
    }
    return planName;
}

export const onlyFirstLetterCapital = (str) => {
    if (str === null || str === "") return "";
    if (str === "Branded Premium" || str === "BRANDED_PREMIUM" || str === "branded premium") return "Branded Premium";
    if (str === "Branded Premium Plus" || str === "BRANDED_PREMIUM_PLUS" || str === "branded premium plus") return "Branded Premium";
    if (str === "Business Advanced" || str === "BUSINESS_ADVANCED" || str === "business advanced") return "Business Advanced";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getOrgNameBasedOnLevel = (adminDetails, levelsDetails) => {
    if (levelsDetails === null) return "";
    switch (adminDetails.getAdminLevel()) {
        case "ENTERPRISE":
            return levelsDetails.getEnterprise().getName();
            break;
        case "REGIONAL":
            return levelsDetails.getRegion().getName();
            break;
        case "SUB_REGIONAL":
            return levelsDetails.getSubRegion().getName();
            break;
    }
}

export const eachWordStartWithCapital = (str) => {
    str = str.replaceAll("_", " ");
    let strArr = str.split(" ");
    let newStrArr = strArr.map(st => st.charAt(0).toUpperCase() + st.slice(1).toLowerCase());
    return newStrArr.join(" ");
}

