export const THIRD_PARTY_BILLING_DETAILS = "THIRD_PARTY_BILLING_DETAILS";

export default (state = null, action) => {
    switch (action.type) {
        case THIRD_PARTY_BILLING_DETAILS:
            return action.payload;
        default:
            return state;
    }
}
