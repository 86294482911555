import React, {useEffect, useRef, useState} from "react";
import DropDownWrapper from "./dropDownWrapper";


const MultiDropDownWrapper = ({listArr = [], onChange, selectedCompanyList}) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const parentRef = useRef(null);

    const handleClick = () => {
        setShowDropDown(!showDropDown);
    }

    const handleOutOfFocus = (ev) => {
        if (parentRef.current && !parentRef.current.contains(ev.target)) setShowDropDown(false);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleOutOfFocus);

        return () => {
            document.removeEventListener("mousedown", handleOutOfFocus);
        }
    })

    return (
        <div className='w-100 position-relative' ref={parentRef}>
            <button className='btn sd-selector w-100' onClick={handleClick}>
                <div className='d-flex justify-content-between align-items-center'>
                    <span className="sd-selector-text">Select Locations</span>
                    {showDropDown && <i className="bi bi-chevron-up"></i>}
                    {!showDropDown && <i className="bi bi-chevron-down"></i>}
                </div>
            </button>
            {showDropDown &&
            <DropDownWrapper listArr={listArr} onChange={onChange} selectedCompanyList={selectedCompanyList}
                             cumulativeSelectedHandler={() => {
                                 setShowDropDown(false)
                             }}/>}
        </div>
    )
}

export default MultiDropDownWrapper;
