import {calculateDiscountAmount} from "./commonHelper";
import downloadIcon from "../../Resources/Image/sd-download.svg"

const csvRowHeader = ["Company Name", "Admin Name", "Admin Email", "Phone Number", "Country", "Current Plan", "Total Orders", "Total Paid Orders", "Total Usage Billed($)", "Your Earning($)"];

export const getEarningCSVDataLink = (fileName, paymentData, accountData, discount) => {
    fileName += ".csv";
    let blob = new Blob([createCSVRows(paymentData, accountData, discount)], {type: "text/csv"});
    let url = window.URL.createObjectURL(blob);
    return (
        <a className="downloadBtn" href={url} download={fileName} title={"Download Details"}>
            <img src={downloadIcon}/>
        </a>
    );
}

const createCSVRows = (paymentData, accountData, discount) => {
    let rowString = csvRowHeader.join(",");
    rowString += "\n";
    paymentData.map((entry) => {
        let row = [];
        let accountDetails = accountData[entry.companyId];
        let discountAmount = calculateDiscountAmount(entry.billableAmount, discount);
        row.push(accountDetails.companyName, accountDetails.adminName, accountDetails.email, accountDetails.phoneNumber, accountDetails.country, accountDetails.currentPlan, entry.numberOfOrders, entry.numberOfPaidOrders, entry.billableAmount, discountAmount);
        let newRow = row.join(",");
        rowString += newRow + "\r\n";
    })
    return rowString;
}