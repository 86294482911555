import React from "react";
import 'react-responsive-modal/styles.css';
import StripeCardWrapper from "../StripeWrapper/stripeCardWrapper";

const AddCreditCardModal = ({isUpdate, show, setShow}) => {
    const onClose = (ev) => {
        document.getElementById("creditCardAddFrom")?.reset();
        setShow(false);
    }
    let modalHeader = "Add Credit Card";
    if (isUpdate) {
        modalHeader = "Update Credit Card";
    }

    return (
        <div className="modal fade" id="creditCardAddModal" tabIndex="-1" aria-labelledby="creditCardAddModal"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-start w-100">{modalHeader}</h3>
                        <button type="button" id="partnerCreditCardModalCloseBtn" className="sd-close"
                                data-bs-dismiss="modal"
                                aria-label="Close" onClick={onClose}>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.6875 9.3125C11.0938 9.6875 11.0938 10.3438 10.6875 10.7188C10.5 10.9062 10.25 11 10 11C9.71875 11 9.46875 10.9062 9.28125 10.7188L6 7.4375L2.6875 10.7188C2.5 10.9062 2.25 11 2 11C1.71875 11 1.46875 10.9062 1.28125 10.7188C0.875 10.3438 0.875 9.6875 1.28125 9.3125L4.5625 6L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L6 4.59375L9.28125 1.3125C9.65625 0.90625 10.3125 0.90625 10.6875 1.3125C11.0938 1.6875 11.0938 2.34375 10.6875 2.71875L7.40625 6.03125L10.6875 9.3125Z"
                                    fill="#242424"/>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <StripeCardWrapper show={show} isUpdate={isUpdate}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCreditCardModal;
