import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import verifyEmailAndPass from "../../Actions/loginAction";

const EMAIL_OR_PASS_MATCHED = "EMAIL_OR_PASS_MATCHED";

const LoginForm = () => {
    const emailInputRef = useRef(null);
    const passInputRef = useRef(null);
    const loginRef = useRef(null);
    const history = useHistory();
    const passMatched = useSelector(state => state.passMatched);
    const dispatch = useDispatch();
    const formSubmitBtnClicked = (event) => {
        event.preventDefault();
        const email = emailInputRef.current.value;
        const pass = passInputRef.current.value;
        loginRef.current.value = "Signing In";
        loginRef.current.disabled = true;
        dispatch(verifyEmailAndPass(email, pass));
    }

    useEffect(() => {
        if (passMatched)
            history.push("/enterprise-portal");
        else if (passMatched === false) {
            loginRef.current.value = "Sign In";
            loginRef.current.disabled = false;
            setTimeout(() => {
                dispatch({type: EMAIL_OR_PASS_MATCHED, payload: null});
            }, 10000);
        }
    }, [passMatched])

    return (
        <div className="w-100 h-100 login-form-section">
            <div className="login-form-wrapper">
                <div className="login-title pb-2">Enterprise Sign In</div>
                <form className="login-form" onSubmit={formSubmitBtnClicked}>
                    <div className="d-flex flex-column">
                        <label className="sd-label" htmlFor="email">Email</label>
                        <input className="sd-input" type="email" ref={emailInputRef}
                               placeholder="Enter email associated with enterprise portal" name="email" required={true}/>
                    </div>
                    <div className="d-flex flex-column">
                        <label className="sd-label" htmlFor="password">Password</label>
                        <input className="sd-input" type="password" ref={passInputRef}
                               placeholder="Enter Password" name="password" required={true}/>
                    </div>
                    <div className="policy mb-2">
                            <span>By clicking Sign In, you agree to Shipday's &nbsp;
                                <a rel="noreferrer" href="https://www.shipday.com/terms"
                                   className="text-color-shipday-green "
                                   target="_blank">Terms of Service</a>
                                &nbsp; and acknowledge our &nbsp;
                                <a rel="noreferrer" href="https://www.shipday.com/privacy"
                                   className="text-color-shipday-green "
                                   target="_blank">privacy policy</a>
                            </span>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <input ref={loginRef} type="submit" className="login-btn login-primary-btn w-100"
                               value="Sign In"/>
                    </div>
                </form>
            </div>
            {passMatched === false &&
            <div className="alert alert-danger text-center wrong-pass-notification" role="alert">
                The email or password you entered is incorrect
            </div>}
        </div>
    );
}

export default LoginForm;
export {EMAIL_OR_PASS_MATCHED};
