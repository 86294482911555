import React from "react";
import TypeSelectionSingleCard from "./typeSelectionSingleCard";
import {
    ANALYTICS_TYPE_TO_TEXT,
    ANALYTICS_TYPES
} from "../../AnalyticsHelperLib/AnalyticsDataHelper/analyticsDataHelperFunctions";

const types = [[ANALYTICS_TYPES.COMPLETED_DELIVERIES, ANALYTICS_TYPE_TO_TEXT.completedDeliveries], [ANALYTICS_TYPES.AVG_PICKUP_TO_DELIVERIES, ANALYTICS_TYPE_TO_TEXT.pickupToDeliveryTime], [ANALYTICS_TYPES.ON_TIME_DELIVERIES, ANALYTICS_TYPE_TO_TEXT.onTimeDeliveries], [ANALYTICS_TYPES.AVG_PLACEMENT_TO_DELIVERY, ANALYTICS_TYPE_TO_TEXT.placementToDeliveryTime]];

const TypeSelector = () => {
    const getCards = () => {
        return types.map((type, ind) => {
            return (
                <div className="col p-0 align-self-center justify-content-center w-100">
                    <TypeSelectionSingleCard key={ind} type={type[0]} text={type[1]}/>
                </div>
            );
        })
    }

    return (
        <div id="analytics-type-selector-div">
            <div className="d-flex row g-0 card-container">
                {getCards()}
            </div>
        </div>
    )
}

export default TypeSelector;
