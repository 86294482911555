export const generateAnalyticsTable = (companyInfo, companyData) => {
    let arr = [], tempArr = [];
    arr = Object.keys(companyData).map(companyId => {
        tempArr = [];
        let val1 = valueSanitization(companyData[companyId].overview['completedDeliveries']);
        let val2 = valueSanitization(companyData[companyId].overview['avgPickupToDeliveryTime']);
        let val3 = valueSanitization(companyData[companyId].overview['onTimeDeliveries']);
        let val4 = valueSanitization(companyData[companyId].overview['avgPlacementToDeliveryTime']);
        tempArr.push(companyInfo[companyId].companyName, val1, val2, val3, val4);
        return tempArr;
    })

    return arr;
}

function valueSanitization(val) {
    if (val === -1 || isNaN(val) || val === undefined) val = 'N/A';
    else val = Number(val).toFixed(2);
    return val;
}

export function typeMapper(selectedType) {
    switch (selectedType) {
        case "pickupToDeliveryTime":
            return "avgPickupToDeliveryTime";
        case "placementToDeliveryTime":
            return "avgPlacementToDeliveryTime";
        default:
            return selectedType;
    }
}
