import {constructPartnerInvoice} from "../Factory/factory"
import {findPartnerPayments} from "../Util/queries";

const PARTNER_INVOICES = "PARTNER_INVOICES";

export const getPartnerInvoices = () => async dispatch => {
    try {
        // let response = await findPartnerPayments();
        let response = {data: null}
        if (response.data === null || response.data === "") {
            response.data = [];
        }
        dispatch({
            type: PARTNER_INVOICES,
            payload: constructPartnerInvoice(response.data)
        })
    } catch (e) {
        console.error(e);
        dispatch({
            type: PARTNER_INVOICES,
            payload: constructPartnerInvoice([])
        })
    }
}

export {PARTNER_INVOICES}
