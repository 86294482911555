export const ANALYTICS_TIME_FRAME = "ANALYTICS_TIME_FRAME";

export default (state = "today", action) => {
    switch (action.type) {
        case ANALYTICS_TIME_FRAME:
            return action.payload;
        default:
            return state;
    }
}

