import React from "react";
import ComponentHeader from "../CommonComponents/componentHeader";
import PayoutsTableSection from "./payoutsTableSection";
import BankDepositSection from "./bankDepositSection";

const PAYOUTS_COMPONENT = "PAYOUTS_COMPONENT";
const PayoutsWrapper = () => {
    return (
        <>
            <ComponentHeader title="Payouts"/>
            <div className="mt-0 m-2 p-3">
                <div className="row p-2">
                    <BankDepositSection/>
                    <PayoutsTableSection/>
                </div>
            </div>
        </>
    )
}

export default PayoutsWrapper;
export {PAYOUTS_COMPONENT};
