import React from "react";

const ComponentHeader = ({title, children}) => {
    return (
        <div className="row mt-4 mb-3 align-items-center border-bottom">
            <div className="col-6 comp-title">{title}</div>
            {children}
        </div>
    )
}

export default ComponentHeader;
