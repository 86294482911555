import {PARTNER_INVOICES} from "../Actions/partnerInvoiceAction";

export default (state = null, action) => {
    switch (action.type) {
        case PARTNER_INVOICES:
            return action.payload;
        default:
            return state;
    }
}
