import React from "react";
import BankInfoHolder from "./bankInfoHolder";

const BankDepositSection = () => {
    return (
        <div className="row mt-2 px-4 payoutsMethod">
            <span className="fs-5">Bank Info</span>
            <BankInfoHolder/>
        </div>
    )
}

export default BankDepositSection;