import React, {useEffect, useRef, useState} from "react";
import {LEFT_SIDE_BAR} from "./leftNavbar";
import {useDispatch, useSelector} from "react-redux";
import {getOrgNameBasedOnLevel} from "../../Util/dataHelper";

const EnterprisePortalHeaderWithBtn = () => {
    const [showBorder, setShowBorder] = useState(false);
    const btnRef = useRef(null);
    const iconRef = useRef(null)
    const dispatch = useDispatch();
    const sideBarActive = useSelector(state => state.leftSideBarActive);
    const enterpriseLevelsDetails = useSelector(state => state.enterpriseLevelsDetails);
    const partnerDetails = useSelector(state => state.partnerDetails);

    const menuBtnClicked = () => {
        if (sideBarActive) {
            iconRef.current.classList.add("collapsed");
        } else {
            iconRef.current.classList.remove("collapsed");
        }
        dispatch({
            type: LEFT_SIDE_BAR,
            payload: !sideBarActive,
        })
    }

    useEffect(() => {
        const scrollHandler = () => {
            document.getElementsByClassName('mainContainer')[0].scrollTop < 30 ? setShowBorder(false) : setShowBorder(true);
        };
        document.getElementsByClassName('mainContainer')[0].addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [showBorder])

    return (
        <div className="p-4 pb-0 d-flex">
            <div className={"menu-icon-wrapper d-flex" + `${showBorder ? " border-b-sm" : ""}`}>
                <div ref={btnRef} className="menuIcon"
                     role="button" onClick={menuBtnClicked} title={"Toggle Navigation"}>
                    <span ref={iconRef} className="navbar-toggle-icon collapsed"><span
                        className="toggle-line"></span></span>
                </div>
                <div className="w-100 text-left lh-sm">
                    <div className="enterprisePortalHeader pt-0">{getOrgNameBasedOnLevel(partnerDetails, enterpriseLevelsDetails)}</div>
                </div>
            </div>

        </div>
    )
}

export default EnterprisePortalHeaderWithBtn;
