import {combineReducers} from "redux";
import leftSideBarReducer from "./leftSideBarReducer";
import partnerBillingDataReducer from "./partnerBillingDataReducer";
import passMatchedReducer from "./passMatchedReducer";
import selectedComponentReducer from "./selectedComponentReducer";
import partnerAccountsReducer from "./partnerAccountsReducer";
import partnerDetailsReducer from "./partnerDetailsReducer";
import partnerInvoicesReducer from "./partnerInvoicesReducer";
import partnerProfileInfoUpdateStateReducer from "./partnerProfileInfoUpadateStateReducer";
import partnerCreditCardInfoReducer from "./partnerCreditCardInfoReducer";
import partnerCreditCardUpdateStateReducer from "./partnerCreditCardUpdateStateReducer";
import partnerBankInfoReducer from "./partnerBankInfoReducer";
import signingUpStateReducer from "./signingUpStateReducer";
import referralLinkReducer from "./referralLinkReducer";
import thirdPartyBillingReducer from "./thirdPartyBillingReducer";
import enterpriseLevelsDetailsReducer from "./enterpriseLevelsDetailsReducer";
import analyticsTimeFrameReducer from "./analyticsTimeFrameReducer";
import analyticsTypeReducer from "./analyticsTypeReducer";
import analyticsDataReducer from "./analyticsDataReducer";
import analyticsOrderCountDataReducer from "./analyticsOrderCountDataReducer";
import companyIdListReducer from "./companyIdListReducer";
import magicLinkReducer from "./magicLinkReducer";
import clientSecretReducer from "./clientSecretReducer";
import achBankInfoReducer from "./achBankInfoReducer";

export default combineReducers({
    leftSideBarActive: leftSideBarReducer,
    partnerBillingData: partnerBillingDataReducer,
    passMatched: passMatchedReducer,
    selectedComponent: selectedComponentReducer,
    partnerAccounts: partnerAccountsReducer,
    partnerDetails: partnerDetailsReducer,
    partnerInvoices: partnerInvoicesReducer,
    partnerProfileInfoUpdateState: partnerProfileInfoUpdateStateReducer,
    partnerCreditCardInfo: partnerCreditCardInfoReducer,
    partnerCreditCardUpdateState: partnerCreditCardUpdateStateReducer,
    partnerBankInfo: partnerBankInfoReducer,
    signingUpState: signingUpStateReducer,
    referralLink: referralLinkReducer,
    thirdPartyBillingDetails: thirdPartyBillingReducer,
    enterpriseLevelsDetails: enterpriseLevelsDetailsReducer,
    analyticsTimeFrame: analyticsTimeFrameReducer,
    analyticsType: analyticsTypeReducer,
    analyticsData: analyticsDataReducer,
    analyticsOrderCount: analyticsOrderCountDataReducer,
    companyIdList: companyIdListReducer,
    magicLink: magicLinkReducer,
    clientSecret: clientSecretReducer,
    achBankInfo: achBankInfoReducer
});
