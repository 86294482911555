import {constructThirdPartyBillingDetails} from "../Factory/factory";
import {THIRD_PARTY_BILLING_DETAILS} from "../Reducers/thirdPartyBillingReducer";

let testData = {
    data: [
        {
            "week": "2022-06-20 - 2022-06-26",
            "billingInfos": []
        },
        {
            "week": "2022-06-13 - 2022-06-19",
            "billingInfos": []
        },
        {
            "week": "2022-06-06 - 2022-06-12",
            "billingInfos": []
        }
    ]
}

const getPartnerThirdPartyBillingDetails = () => async dispatch => {
    try {
        //let response = await findThirdPartyWeeklyBillingDetails();
        let response = testData;
        if (response.data) {
            let thirdPartyBillingObj = constructThirdPartyBillingDetails(response.data);
            if (Object.keys(thirdPartyBillingObj.weeklyBillingFormattedObj).length > 0) {
                dispatch({
                    type: THIRD_PARTY_BILLING_DETAILS,
                    payload: thirdPartyBillingObj
                })
            } else {
                dispatch({
                    type: THIRD_PARTY_BILLING_DETAILS,
                    payload: null
                })
            }
        }
    } catch (e) {
        console.error(e);
        dispatch({
            type: THIRD_PARTY_BILLING_DETAILS,
            payload: null
        })
    }
}

export default getPartnerThirdPartyBillingDetails;
