import React, {useEffect, useState} from "react";

const Selectable = ({dataObj, addVal, removeVal, arr, initiallyClicked}) => {
    const [clicked, setClicked] = useState(initiallyClicked);
    const handleClick = () => {
        if(clicked) removeVal(dataObj.value);
        else addVal(dataObj.value);

        setClicked(!clicked);
    }

    useEffect(()=>{
        if(arr.length === 0) setClicked(false);
    },[arr])

    return (
        <div className="form-check py-1">
            <input className="form-check-input" type="checkbox" value="" id={"multi-checkbox-" + dataObj.value} onChange={handleClick} checked={clicked}/>
                <label className="form-check-label w-100 cur-pointer" htmlFor={"multi-checkbox-" + dataObj.value}>
                    {dataObj.label}
                </label>
        </div>
    )
}

export default Selectable;
