import React from "react";
import {BILLING_COMPONENT} from "../Billing/billingPageWrapper";
import {constructSelectedComponent} from "../../Factory/factory";
import {LEFT_SIDE_BAR_SELECTED, PARTNER_TYPE_AFFILIATE} from "../EnterprisePortal/leftNavbar";
import {useDispatch} from "react-redux";
import {EARNING_COMPONENT} from "../Earning/earningPageWrapper";

const DashboardInfoCard = ({number, details, goToDetails, partnerType}) => {
    const dispatch = useDispatch();
    const onClick = () => {
        if (goToDetails) {
            if (partnerType === PARTNER_TYPE_AFFILIATE)
                dispatch({
                    type: LEFT_SIDE_BAR_SELECTED,
                    payload: constructSelectedComponent(EARNING_COMPONENT)
                })
            else
                dispatch({
                    type: LEFT_SIDE_BAR_SELECTED,
                    payload: constructSelectedComponent(BILLING_COMPONENT)
                })
        }
    }

    return (
        <div className="card" onClick={onClick}>
            <div className="d-flex flex-column card-body">
                <span className="card-text">{details}</span>
                <span className="card-hero">{number}</span>
            </div>
        </div>
    )
}

export default DashboardInfoCard;

