import {CARD_UPDATE_STATES, PARTNER_CREDIT_UPDATE_CARD_STATE} from "../Components/Payments/creditCardInfoSection";
import {updatePartnerPaymentCard} from "../Util/queries";

export const UpdatePartnerCard = (email, stripe_token) => async (dispatch) => {
    try {
        let data = {
            "email": email,
            "stripeToken": stripe_token
        }
        let request = await updatePartnerPaymentCard(data);
        // let request = {status: 200};
        if (request.status === 200 || request.status === 201 || request.status === 204) {
            dispatch({
                type: PARTNER_CREDIT_UPDATE_CARD_STATE,
                payload: CARD_UPDATE_STATES.success
            })
        } else throw new Error(request.statusText);
    } catch (e) {
        console.error(e);
        dispatch({
            type: PARTNER_CREDIT_UPDATE_CARD_STATE,
            payload: CARD_UPDATE_STATES.failed
        })
    }
}
