import {getJwtToken} from "./localCache";

export const getConfigWithAuth = () => {
    return {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${getJwtToken()}`,
        },
        dataType: "application/json"
    }
}

export const getConfigWithoutAuth = () => {
    return {dataType: "application/json"}
}
