import {PARTNER_CREDIT_CARD_INFO} from "../Components/Payments/creditCardInfoSection";

export default (state = null, action) => {
    switch (action.type) {
        case PARTNER_CREDIT_CARD_INFO:
            return action.payload;
        default:
            return state
    }
}
