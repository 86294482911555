import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ANALYTICS_SELECTED_TYPE} from "../../Reducers/analyticsTypeReducer";
import {ANALYTICS_TYPES} from "../../AnalyticsHelperLib/AnalyticsDataHelper/analyticsDataHelperFunctions";

const TypeSelectionSingleCard = ({type, text}) => {
    const selectedType = useSelector(state => state.analyticsType);
    const analyticsData = useSelector(state => state.analyticsData);
    const companyIdList = useSelector(state => state.companyIdList);
    const [overviewVal, setOverViewVal] = useState("N/A");
    let overView = "N/A";

    useEffect(() => {
        if (companyIdList !== null && companyIdList.getCompanyList().length !== 0) {
            overView = analyticsData.getCompanyWiseCumulativeOverView(companyIdList.getCompanyList(), type);
            //overView = overViewObj[type];
            if (isNaN(overView) || overView === undefined) overView = 'N/A';
            else if (overView === 0) overView = '0';
            else if (type !== ANALYTICS_TYPES.COMPLETED_DELIVERIES)
                overView = overView.toFixed(2);

            setOverViewVal(overView);
        } else {
            if (analyticsData) {
                overView = analyticsData.getOverview(type);
                if (isNaN(overView) || overView === undefined) overView = 'N/A';
                else if (overView === 0) overView = '0';
                else if (type !== ANALYTICS_TYPES.COMPLETED_DELIVERIES)
                    overView = overView.toFixed(2);

                setOverViewVal(overView);
            }
        }
    }, [companyIdList, analyticsData]);

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch({
            type: ANALYTICS_SELECTED_TYPE,
            payload: type
        })
    }

    return (
        <div key={type} className={`card analytics-cards ${selectedType === type ? 'card-selected' : ''}`}
             id={`type_${type}`}
             data-type={type} onClick={handleClick}>
            <div className="card-body d-flex flex-column">
                <span className="card-top">{text}</span>
                <span className="card-hero">{overviewVal}</span>
            </div>
        </div>
    )
}

export default TypeSelectionSingleCard;
