import {ANALYTICS_TYPES} from "../AnalyticsHelperLib/AnalyticsDataHelper/analyticsDataHelperFunctions";

export const ANALYTICS_SELECTED_TYPE = 'ANALYTICS_SELECTED_TYPE';

export default (state = ANALYTICS_TYPES.COMPLETED_DELIVERIES, action) => {
    switch (action.type) {
        case ANALYTICS_SELECTED_TYPE:
            return action.payload;
        default:
            return state;
    }
}
