export class EnterpriseInfo {
    constructor(id, name, address, phoneNumber) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.phoneNumber = phoneNumber;
    }

    getName() {
        return this.name;
    }
}

export class RegionInfo {
    constructor(id, name, address, phoneNumber, enterpriseId) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.phoneNumber = phoneNumber;
        this.enterpriseId = enterpriseId;
    }

    getName() {
        return this.name;
    }
}

export class SubRegionInfo {
    constructor(id, name, address, phoneNumber, regionId) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.phoneNumber = phoneNumber;
        this.regionId = regionId;
    }

    getName() {
        return this.name;
    }
}

export class ACHBankInfo {
    constructor(bankName, last4Digits, routingNo, paymentMethodId, customerId) {
        this.bankName = bankName;
        this.last4Digits = last4Digits;
        this.routingNo = routingNo;
        this.paymentMethodId = paymentMethodId;
        this.customerId = customerId;
    }
}

