import {constructEnterpriseBillingDetails} from "../Factory/factory";
import {findEnterpriseBilling} from "../Util/queries";

const PARTNER_BILLING = "PARTNER_BILLING"

export const getPartnerBillingDetails = () =>
    async (dispatch) => {
        try {
            let response = await findEnterpriseBilling();
            //let response = {data: testData};
            if (response.data) {
                let partnerBillingDetailsObj = constructEnterpriseBillingDetails(response.data);
                dispatch({
                    type: PARTNER_BILLING,
                    payload: partnerBillingDetailsObj
                })
            } else {
                dispatch({
                    type: PARTNER_BILLING,
                    payload: null
                })
            }
        } catch (e) {
            console.error(e)
            dispatch({
                type: PARTNER_BILLING,
                payload: null
            })
        }
    }

export {PARTNER_BILLING}