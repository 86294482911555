export const REFERRAL_LINK = "REFERRAL_LINK";

export default (state = null, action) => {
    switch (action.type) {
        case REFERRAL_LINK:
            return action.payload;
        default:
            return state;
    }
}
