import {CARD_UPDATE_STATES, PARTNER_CREDIT_UPDATE_CARD_STATE} from "../Components/Payments/creditCardInfoSection";

export default (state = CARD_UPDATE_STATES.none, action) => {
    switch (action.type) {
        case PARTNER_CREDIT_UPDATE_CARD_STATE:
            return action.payload
        default:
            return state
    }
}
