import {getFullDate} from "./commonHelper";
import {onlyFirstLetterCapital} from "../dataHelper";
import MagicLink from "../../Components/Dashboard/magicLink";
import magicLink from "../../Components/Dashboard/magicLink";

const dashboardAccountsTableRowArrangement = ["companyName", "adminName", "email", "phoneNumber", "currentPlan", "country", "magicLink"]

export const getDashboardAccountsTableData = (accounts = {}) => {
    let tableRowsArr = [];
    let index = 1;
    for (const companyId in accounts) {
        let tableCol = [index + "."];
        tableCol.push(...dashboardAccountsTableRowArrangement.map((key) => {
            let colData = accounts[companyId][key];
            if (key === "currentPlan") {
                colData = onlyFirstLetterCapital(colData);
            } else if (key === "memberSince")
                colData = getFullDate(colData)
            else if (key === "remove")
                colData = getRemoveBtn();
            else if (key === "magicLink"){
                colData = getMagicLinkBtn(accounts[companyId]["adminId"], accounts[companyId]["companyId"]);
            }

            return colData || "Unknown";
        }))
        tableRowsArr.push(tableCol);
        index++;
    }
    return tableRowsArr;
}

const getRemoveBtn = (companyId) => {
    return (
        <a href="javascript:void(0)" onClick={() => {
            removeCompany(companyId)
        }}>Remove</a>
    );
}

const removeCompany = (companyId) => {

}

const getMagicLinkBtn = (adminId, companyId) => {
    return <MagicLink selectedCompanyId={companyId} selectedAdminId={adminId}/>
}
