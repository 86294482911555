import {
    AccountMagicLink, ClientSecret,
    CredentialsObj,
    EnterpriseAccountsObject,
    EnterpriseAdminDetailsObj,
    EnterpriseBillingDetailsObject,
    EnterpriseLevelsInformation,
    PartnerBankInfo,
    PartnerCreditCardDetailsObject,
    PartnerInvoicesObject,
    PartnerThirdPartyBillingDetailsObject,
    SelectedPageComponent
} from "../Model/businessObjects";
import {DASHBOARD_COMPONENT} from "../Components/Dashboard/dashboardWrapper";
import {AnalyticsData, SelectedCompanyForAnalytics} from "../Model/analyticsObjects";
import {ANALYTICS_COMPONENT} from "../Components/Analytics/analyticsWrapper";
import {ACHBankInfo} from "../Model/enterpriseObjects";

export const constructCredentialsObj = (email, pass) => {
    return new CredentialsObj(email, pass);
}

export const constructSelectedComponent = (component = ANALYTICS_COMPONENT) => {
    return new SelectedPageComponent(component);
}

export const constructEnterpriseDetails = (adminObject) => {
    return new EnterpriseAdminDetailsObj(adminObject);
}

export const constructEnterpriseLevelsDetails = (levelsInfo) => {
    return new EnterpriseLevelsInformation(levelsInfo);
}

export const constructEnterpriseAccounts = (accountsArray) => {
    return new EnterpriseAccountsObject(accountsArray);
}

export const constructEnterpriseBillingDetails = (billingArray) => {
    return new EnterpriseBillingDetailsObject(billingArray);
}

export const constructAnalyticsDetails = (rawData, selectedDate, selectedType) => {
    return new AnalyticsData(rawData, selectedDate, selectedType);
}

export const constructPartnerInvoice = (invoicesArr) => {
    return new PartnerInvoicesObject(invoicesArr);
}

export const constructPartnerCardDetails = (last4Digits, expireMonth, expireYear) => {
    return new PartnerCreditCardDetailsObject(last4Digits, expireMonth, expireYear);
}

export const constructPartnerBankInfo = (name, routing, acc, paypal) => {
    return new PartnerBankInfo(name, routing, acc, paypal);
}

export const constructThirdPartyBillingDetails = (billingArr) => {
    return new PartnerThirdPartyBillingDetailsObject(billingArr);
}

export const constructCompanyIdList = (listArr) => {
    return new SelectedCompanyForAnalytics(listArr);
}

export const constructMagicLink = (link) => {
    return new AccountMagicLink(link);
}

export const constructClientSecret = (secret) => {
    return new ClientSecret(secret);
}

export const constructACHBankInfo = (bankName, last4Digits, routingNo, email, customerId) => {
    return new ACHBankInfo(bankName, last4Digits, routingNo, email, customerId);
}
