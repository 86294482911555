import {formatCurrentPlan, getTotalThirdPartyOrdersByWeek} from "../Util/dataHelper";
import {EnterpriseInfo, RegionInfo, SubRegionInfo} from "./enterpriseObjects";

export class CredentialsObj {
    constructor(email, pass) {
        this.email = email;
        this.pass = pass;
    }
}

export class SelectedPageComponent {
    constructor(component) {
        this.component = component
    }
}

export class EnterpriseLevelsInformation {
    constructor(enterpriseData) {
        this.enterpriseInfo = {};
        this.regionInfo = {};
        this.subRegionInfo = {};
        this.populateInfo(enterpriseData);
    }

    populateInfo(enterpriseData) {
        let _this = this;
        let enterpriseId = enterpriseData.id;
        let regionId;
        _this.enterpriseInfo = new EnterpriseInfo(enterpriseData.id, enterpriseData.name, enterpriseData.address, enterpriseData.phoneNumber);
        enterpriseData.regions.forEach(region => {
            _this.regionInfo[region.id] = new RegionInfo(region.id, region.name, region.address, region.phoneNumber, enterpriseId);
            regionId = region.id;
            region.subRegions.forEach(subRegion => {
                _this.subRegionInfo[subRegion.id] = new SubRegionInfo(subRegion.id, subRegion.name, subRegion.address, subRegion.phoneNumber, regionId);
            })
        })
    }

    getRegionAndSubRegionCounts() {
        return [Object.keys(this.regionInfo).length, Object.keys(this.subRegionInfo).length];
    }

    getEnterprise() {
        return this.enterpriseInfo;
    }

    getRegion(id) {
        if (id === undefined) return this.regionInfo[Object.keys(this.regionInfo)[0]];

        return this.regionInfo[id];
    }

    getSubRegion(id) {
        if (id === undefined) return this.subRegionInfo[Object.keys(this.subRegionInfo)[0]];

        return this.subRegionInfo[id];
    }
}

export class EnterpriseAdminDetailsObj {
    constructor(adminObj) {
        this.id = adminObj.id;
        this.accessLevel = adminObj.accessLevel;
        this.name = adminObj.name;
        this.administratorLevel = adminObj.administratorLevel;
        this.email = adminObj.email;
        this.accessId = adminObj.accessId;
        this.billingDiscountAmount = adminObj.billingDiscountAmount;
    }

    getDiscountAmount() {
        return this.billingDiscountAmount;
    }

    getAdminNameAndEmail() {
        return [this.name, this.email];
    }

    getAdminLevel() {
        return this.administratorLevel;
    }
}

export class EnterpriseAccountsObject {
    constructor(rawData) {
        this.getAccountsArrFormatted(rawData);
        this.accountsArrFormatted = [];
        this.accountsObj = {};
        this.totalPaidAccounts = 0;
        this.constructAccountObjects();
    }

    getAccountsArrFormatted(enterpriseData) {
        let regionID, subRegionID;
        let companyArr = [];
        enterpriseData.regions.forEach((region) => {
            regionID = region.regionId;
            region.subRegions.forEach((subRegion) => {
                subRegionID = subRegion.subRegionId;
                subRegion.companies.forEach((company) => {
                    company.regionId = regionID;
                    company.subRegionId = subRegionID;
                    companyArr.push(company);
                })
            })
        })
        this.accountsArrRaw = companyArr;
    }

    constructAccountObjects() {
        this.accountsArrRaw.forEach((account) => {
            account.memberSince = account.memberSince || "N/A";
            account.memberSince = account.memberSince.split("T")[0];
            account.currentPlan = formatCurrentPlan(account.currentPlan)
            let accountObj = new EnterpriseCompanyObject(account.regionId, account.subRegionId, account.companyId, account.adminId, account.companyName, account.adminName, account.email, account.phone, account.country, account.currentPlan, account.memberSince, account.paidAccount)
            this.accountsObj[account.companyId] = accountObj;
            this.accountsArrFormatted.push(accountObj);
            if (accountObj.paidAccount) this.totalPaidAccounts++;
        })
    }

    getAccountsArray() {
        return this.accountsArrFormatted;
    }

    getAccountsObj() {
        return this.accountsObj;
    }

    getAccountDetailsByCompanyId(companyId) {
        return this.accountsObj[companyId];
    }

    getTotalCountOfActiveAccounts() {
        return this.accountsArrFormatted.length;
    }

    getTotalCountOfPaidAccounts() {
        return this.totalPaidAccounts;
    }
}

export class EnterpriseCompanyObject {
    constructor(regionId, subRegionId, companyId, adminId, companyName, adminName, email, phoneNumber, country, currentPlan, memberSince, paidAccount) {
        this.regionId = regionId;
        this.subRegionId = subRegionId;
        this.companyId = companyId;
        this.adminId = adminId;
        this.companyName = companyName;
        this.adminName = adminName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.country = country;
        this.currentPlan = currentPlan;
        this.memberSince = memberSince;
        this.paidAccount = paidAccount;
        this.checkPaidAccountState();
    }

    checkPaidAccountState() {
        if(this.paidAccount !== undefined) return;
        if(this.currentPlan === "Starter" || this.currentPlan.includes("Trial")) this.paidAccount = false;
        else this.paidAccount = true;
    }
}

export class EnterpriseBillingDetailsObject {
    constructor(billingObj) {
        this.billingFormattedObj = this.getFormatterBillingArr(billingObj);
        this.calculateTotals();
    }

    getFormatterBillingArr(rawData) {
        let dateTracker = {};
        let enterpriseId = rawData.enterpriseId;
        let regionId, subregionId, companyId;
        rawData.regions.forEach(region => {
            regionId = region.regionId;
            region.subRegions.forEach(subRegion => {
                subregionId = subRegion.subRegionId;
                subRegion.companies.forEach(company => {
                    companyId = company.companyId;
                    company.billingInfos.forEach(bill => {
                        if (dateTracker[bill.date] === undefined) {
                            dateTracker[bill.date] = {date: bill.date};
                            dateTracker[bill.date].billingInfos = [];
                        }
                        bill.companyId = companyId;
                        bill.subRegionId = subregionId;
                        bill.regionId = regionId;
                        dateTracker[bill.date].billingInfos.push(bill);
                    })
                })
            })
        })
        return dateTracker;
    }

    calculateTotals() {
        let _this = this;
        let totalOrders = 0;
        let totalPaidOrders = 0;
        let totalBillingAmount = 0
        Object.keys(_this.billingFormattedObj).forEach(date => {
            totalOrders = 0;
            totalPaidOrders = 0;
            totalBillingAmount = 0;
            _this.billingFormattedObj[date].billingInfos.forEach(billingData => {
                totalOrders += billingData.numberOfOrders;
                totalPaidOrders += billingData.numberOfPaidOrders;
                totalBillingAmount += billingData.billableAmount;
            });
            _this.billingFormattedObj[date].totalOrders = totalOrders;
            _this.billingFormattedObj[date].totalPaidOrders = totalPaidOrders;
            _this.billingFormattedObj[date].totalBillingAmount = totalBillingAmount;
            _this.billingFormattedObj[date].billedAccountsCount = _this.billingFormattedObj[date].billingInfos.length;
        })
    }

    getBillingInfoByDate(date) {
        return this.billingFormattedObj[date];
    }
}

class PartnerSingleInvoiceObj {
    constructor(chargeId, customerId, totalAmountPaid, invoiceUrl, chargeTimeInSec, currency, isSuccessful) {
        this.chargeId = chargeId;
        this.customerId = customerId;
        this.totalAmountPaid = Number(Number(totalAmountPaid) / 100).toFixed(2);
        this.invoiceUrl = invoiceUrl;
        this.chargeTime = Number(chargeTimeInSec) * 1000;
        this.currency = currency;
        this.isSuccessful = isSuccessful;
    }
}

export class PartnerInvoicesObject {
    constructor(invoicesArr) {
        this.partnerInvoicesArr = [];
        this.constructPartnerInvoicesArr(invoicesArr);
    }

    constructPartnerInvoicesArr(invoicesArr) {
        this.partnerInvoicesArr = invoicesArr.map((invoice) => {
            return new PartnerSingleInvoiceObj(invoice.chargeId, invoice.customerId, invoice.totalAmountPaid, invoice.invoiceUrl, invoice.chargeTime, invoice.currency, invoice.isSuccessful)
        })
    }

    getInvoicesArr() {
        return this.partnerInvoicesArr;
    }
}

export class PartnerCreditCardDetailsObject {
    constructor(last4Digits, expireMonth, expireYear) {
        this.last4Digits = last4Digits;
        this.expireMonth = expireMonth;
        this.expireYear = expireYear;
    }
}

export class PartnerBankInfo {
    constructor(name, routing, acc, paypal) {
        this.name = name;
        this.routing = routing;
        this.acc = acc;
        this.paypal = paypal;
    }
}

export class PartnerThirdPartyBillingDetailsObject {
    constructor(weeklyBillingArr) {
        this.rawWeeklyBillingArr = weeklyBillingArr;
        this.weeklyBillingFormattedObj = {};
        this.constructWeeklyBillingFormattedObj();
    }

    constructWeeklyBillingFormattedObj() {
        this.rawWeeklyBillingArr.forEach((billingInfoByWeek) => {
            let totalsInfo = getTotalThirdPartyOrdersByWeek(billingInfoByWeek.billingInfos);
            billingInfoByWeek = Object.assign(billingInfoByWeek, totalsInfo)
            if (totalsInfo.billedAccountsCount != 0) {
                this.weeklyBillingFormattedObj[billingInfoByWeek.week] = billingInfoByWeek;
            }
        })
    }

    getBillingInfoByDate(date) {
        return this.weeklyBillingFormattedObj[date];
    }
}


export class AccountMagicLink {
    constructor(magicLink) {
        this.magicLink = magicLink;
    }

    getMagicLink() {
        return this.magicLink;
    }
}

export class ClientSecret {
    constructor(secret) {
        this.secret = secret;
    }

    getSecret() {
        return this.secret;
    }
}

