import React, {useState} from "react";
import MultiDropDownWrapper from "./CustomMultiDropDown/multiDropDownWrapper";
import {getCompanyIdNamePair,} from "../../AnalyticsHelperLib/AnalyticsDataHelper/analyticsDataHelperFunctions";
import {useDispatch, useSelector} from "react-redux";
import {COMPANY_ID_LIST} from "../../Reducers/companyIdListReducer";
import {constructCompanyIdList} from "../../Factory/factory";

const MultiDropDownComponent = () => {
    const enterpriseAccounts = useSelector(state => state.partnerAccounts);
    const dispatch = useDispatch();
    const [selectedCompanyList, setSelectedCompanyList] = useState([]);

    const updateSelectedList = (selectedCompanyIdArr) => {
        setSelectedCompanyList(selectedCompanyIdArr);
        dispatch({
            type: COMPANY_ID_LIST,
            payload: constructCompanyIdList(selectedCompanyIdArr)
        })
    }

    if (enterpriseAccounts === null) return <></>;

    return (
        <div className='multi-dropdown'>
            <MultiDropDownWrapper listArr={getCompanyIdNamePair(enterpriseAccounts.getAccountsArray())}
                                  onChange={updateSelectedList} selectedCompanyList={selectedCompanyList}/>
        </div>
    )
}

export default MultiDropDownComponent;
