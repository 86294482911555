import React from "react";
import {useSelector} from "react-redux";
import SingleInfoSection from "./singleInfoSection";
import ComponentHeader from "../CommonComponents/componentHeader";
import {eachWordStartWithCapital} from "../../Util/dataHelper";
import PasswordUpdateSection from "./passwordUpdateSection";
import BillingInformationUpdate from "./billingInfoUpdate";

const PROFILE_COMPONENT = "PROFILE_COMPONENT";
const BASE_SIGNUP_URL = "https://dispatch.shipday.com/signUp?pref=";

const ProfilePageWrapper = () => {
    const enterpriseAdminDetails = useSelector(state => state.partnerDetails);
    const enterpriseLevelsDetails = useSelector(state => state.enterpriseLevelsDetails);
    const enterpriseCompanyAccounts = useSelector(state => state.partnerAccounts);
    const count = enterpriseLevelsDetails.getRegionAndSubRegionCounts();
    const managingText = `${count[0]} Region(s), ${count[0]} Subregion(s) and ${enterpriseCompanyAccounts.getAccountsArray().length} Companies`
    if (enterpriseAdminDetails === null)
        return <></>

    return (
        <>
            <ComponentHeader title="Profile"/>
            <div className="mx-auto d-flex justify-content-center">
                <div className="profile-div">
                    <span className="sub-title">Personal Information</span>
                    <div className="mt-2 mb-3 info-container lh-lg">
                        <SingleInfoSection label={"Name"} info={enterpriseAdminDetails.name}/>
                        <SingleInfoSection label={"Address:"} info={enterpriseAdminDetails.address}/>
                        <SingleInfoSection label={"Email:"} info={enterpriseAdminDetails.email}/>
                        <SingleInfoSection label={"Administrator Level:"}
                                           info={eachWordStartWithCapital(enterpriseAdminDetails.administratorLevel)}/>
                        <SingleInfoSection label={"Managing:"} info={managingText}/>
                    </div>
                    {/*<PasswordUpdateSection/>*/}
                    <BillingInformationUpdate/>
                </div>
            </div>
        </>
    )
}

export default ProfilePageWrapper;
export {PROFILE_COMPONENT};
