import React from "react";
import PaymentModalWrapper from "./paymentModalWrapper";
import BankInfoAddModalContent from "./bankInfoAddModalContent";
import MandateModalContent from "./mandateModalContent";
import {useDispatch} from "react-redux";
import {getClientSecret} from "../../Actions/clientSecretAction";
import SuccessModalContent from "./successModalContent";
import ErrorModalContent from "./errorModalContent";

export const ACH_BANK_INFO_MODAL_ID = "achBankInfoModal";
export const ACH_MANDATE_CONFIRMATION_MODAL_ID = "achMandateConfModal";
export const ACH_SUCCESS_CONFIRMATION_MODAL_ID = "achSuccessConfModal";
export const ACH_ERROR_MODAL_ID = "achErrorConfModal";

const AddBankDetails = () => {
    const dispatch = useDispatch();
    const stripe = window.Stripe('pk_live_goDpxfRC7Z3f2CVPmuUsAX6Q00GAYHagBy');
    const handleClick = () => {
        dispatch(getClientSecret());
    }

    return (
        <>
            <div className="bank-no-info">
                <div>No bank information is currently available</div>
            </div>
            <div className="bank-info-add-btn" data-bs-toggle="modal"
                 data-bs-target={`#${ACH_BANK_INFO_MODAL_ID}`} onClick={handleClick}>Add bank Information</div>
            <PaymentModalWrapper title={"Add Bank Information"} modalId={ACH_BANK_INFO_MODAL_ID} children={<BankInfoAddModalContent stripe={stripe}/>}/>
            <PaymentModalWrapper title={"Confirmation"} modalId={ACH_MANDATE_CONFIRMATION_MODAL_ID} children={<MandateModalContent stripe={stripe}/>}/>
            <PaymentModalWrapper title={""} modalId={ACH_SUCCESS_CONFIRMATION_MODAL_ID} children={<SuccessModalContent/>} showFooterBtn={false}/>
            <PaymentModalWrapper title={""} modalId={ACH_ERROR_MODAL_ID} children={<ErrorModalContent/>} showFooterBtn={false}/>
        </>
    )
}

export default AddBankDetails;
