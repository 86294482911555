import React from "react";
import {Redirect, Route} from "react-router-dom";
import {getJwtRefreshToken} from "./Util/localCache";
import updateAccessToken from "./Actions/authRefreshAction";
import {useDispatch} from "react-redux";

const ProtectedRoute = ({component: Component, ...otherProps}) => {
    const dispatch = useDispatch();
    return (
        <Route {...otherProps} render={
            props => {
                let storedToken = getJwtRefreshToken();
                dispatch(updateAccessToken());
                if (storedToken !== "" && storedToken !== null && storedToken.length > 0) return <Component {...otherProps} {...props}/>
                return (<Redirect to={
                    {
                        pathname: "/login",
                        state: {
                            from: props.location
                        }
                    }
                }/>)
            }
        }/>
    )
}

export default ProtectedRoute;
