import {EMAIL_OR_PASS_MATCHED} from "../Components/Login/loginForm";

export default (state = null, action) => {
    switch (action.type) {
        case EMAIL_OR_PASS_MATCHED:
            return action.payload;
        default:
            return state;
    }
}
