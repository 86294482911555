import {setBillingInformation} from "../Util/queries";
import {PARTNER_PROFILE_UPDATE_STATE} from "./passwordUpdateAction";
export const BILLING_UPDATE_SUCCESSFUL = "BILLING_UPDATE_SUCCESSFUL";
export const BILLING_UPDATE_FAILED = "BILLING_UPDATE_FAILED"

export const updateBillingInformation = (data) => async dispatch => {
    try {
        let response = await setBillingInformation(data);
        if (response.status === 200) {
            dispatch({
                type: PARTNER_PROFILE_UPDATE_STATE,
                payload: BILLING_UPDATE_SUCCESSFUL
            });
        }
    } catch (e) {
        console.error(e);
        dispatch({
            type: PARTNER_PROFILE_UPDATE_STATE,
            payload: BILLING_UPDATE_FAILED
        });
    }
}
