import React, {useRef} from "react";

const SingleInfoSection = ({label, info, showCopyBtn, copyBtnText}) => {
    const clipboardRef = useRef(null);
    if (info === null || info === undefined) return <></>

    const copyToClipBoard = () => {
        clipboardRef.current.textContent = "Copied";
        navigator.clipboard.writeText(info);
        setTimeout(() => {
            clipboardRef.current.textContent = copyBtnText;
        }, 2000)
    }

    return (
        <div className="single-info justify-content-between">
            <div className="info-header"><span>{label}</span></div>
            <div className="info-text">{info}</div>
                {showCopyBtn &&
                <div className="info-btn copy-text-btn text-end" ref={clipboardRef}
                     onClick={copyToClipBoard}>{copyBtnText}</div>}
        </div>
    )
}

export default SingleInfoSection;