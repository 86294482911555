import React from "react";
import TableWrapper from "../Table/tableWrapper";
import {useSelector} from "react-redux";
import {getUsageTable} from "../../Util/TableHelpers/usageTableHelper";

const UsageTableHeaders = ["Service Period", "Total Number of Accounts", "Total Number of Billed Accounts", "Total Usage Cost($)", "Total Discount($)", "Amount Due($)", "Details"]

const UsageTableSection = () => {
    const tableOption = {
        firstChildHighlight: true
    }
    const partnerDetails = useSelector(state => state.partnerDetails);
    const partnerAccountsObj = useSelector(state => state.partnerAccounts);
    const billingDataObj = useSelector(state => state.partnerBillingData);

    if (billingDataObj === null || partnerDetails == null || partnerAccountsObj == null)
        return (
            <div className="row p-2 ps-0 min-width-md-parent">
                <span className="sub-title">Usage</span>
                <TableWrapper tableHeaderArr={UsageTableHeaders} tableBodyArr={[]}
                              errorText="You do not have any usage"/>
            </div>
        )

    const tableBodyArr = getUsageTable(billingDataObj.billingFormattedObj, partnerDetails.billingDiscountAmount, partnerAccountsObj);
    UsageTableHeaders[4] = "Total Discount($)" + ` (${partnerDetails.billingDiscountAmount}%)`;

    return (
        <div className="row ps-0 min-width-md-parent">
            <span className="sub-title">Usage</span>
            <TableWrapper tableHeaderArr={UsageTableHeaders} tableBodyArr={tableBodyArr} option={tableOption}/>
        </div>
    )
}

export default UsageTableSection;

