import React from "react";
import EarningsTableSection from "./earningsTableSection";
import ComponentHeader from "../CommonComponents/componentHeader";

const EARNING_COMPONENT = "EARNING_COMPONENT";


const EarningPageWrapper = () => {
    return (
        <>
            <ComponentHeader title="Earnings"/>
            <div className="mt-0 m-2 p-3">
                <EarningsTableSection/>
            </div>
        </>
    )
}

export default EarningPageWrapper;
export {EARNING_COMPONENT};