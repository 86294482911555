import React from "react";
import {useSelector} from "react-redux";

const BankDetails = () => {
    const bankInfo = useSelector(state => state.achBankInfo);

    // const bankInfo = {
    //     bankName: "Bank of America",
    //     last4Digits: "1234",
    //     routingNo: "123456789"
    // }

    return (
        <>
            <div className="bank-info-available d-flex">
                {/*<div className="bank-icon-info">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"*/}
                {/*         className="bi bi-bank" viewBox="0 0 16 16">*/}
                {/*        <path*/}
                {/*            d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>*/}
                {/*    </svg>*/}
                {/*</div>*/}
                <div className="bank-details-texts">
                    {bankInfo.bankName}, ****{bankInfo.last4Digits}
                    {/*<div className="bank-number">Account number ending with {bankInfo.last4Digits}</div>*/}
                    {/*<div className="bank-number">Routing number {bankInfo.routingNo}</div>*/}
                </div>
            </div>
            {/*<div className="bank-verify-btn">*/}
            {/*    Remove*/}
            {/*</div>*/}
        </>
    )
}

export default BankDetails;
