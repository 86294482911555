import {fetchACHBankInfo} from "../Util/queries";
import {constructACHBankInfo} from "../Factory/factory";
import {ACH_BANK_INFO_REDUCER} from "../Reducers/achBankInfoReducer";

export const getACHBankInfo = () => async dispatch =>{
    try {
        let response = await fetchACHBankInfo();

        if (response.data?.achInfo) {
            let achInfoBankObj = constructACHBankInfo(response.data.achInfo.bankName, response.data.achInfo.last4, response.data.achInfo.routingNumber, response.data.achInfo.paymentMethodId, response.data.achInfo.stripeCustomerId);
            dispatch({
                type: ACH_BANK_INFO_REDUCER,
                payload: achInfoBankObj
            })
        } else {
            dispatch({
                type: ACH_BANK_INFO_REDUCER,
                payload: null
            })
        }
    } catch (e) {
        console.error(e);
        dispatch({
            type: ACH_BANK_INFO_REDUCER,
            payload: null
        })
    }
}
