import React from "react";
import {useSelector} from "react-redux";
import TableWrapper from "../Table/tableWrapper";
import {getThirdPartyUsageTable} from "../../Util/TableHelpers/thirdPartyUsageTable";

const ThirdPartyTableHeaders = ["Date", "Billing Amount($)", "Outstanding Balance($)", "Details"];

const ThirdPartyTableSection = () => {
    const tableOption = {
        firstChildHighlight: true
    }

    const partnerDetails = useSelector(state => state.partnerDetails);
    const partnerAccountsObj = useSelector(state => state.partnerAccounts);
    const thirdPartyBillingDetails = useSelector(state => state.thirdPartyBillingDetails);

    if (thirdPartyBillingDetails == null || partnerDetails == null || partnerAccountsObj == null)
        return (
            <div className="row p-2 min-width-md-parent">
                <span className="fs-5">Usage</span>
                <TableWrapper tableHeaderArr={ThirdPartyTableHeaders} tableBodyArr={[]}
                              errorText="You do not have any account with third party usage"/>
            </div>
        )

    const tableBodyArr = getThirdPartyUsageTable(thirdPartyBillingDetails.weeklyBillingFormattedObj, partnerAccountsObj.getAccountsObj());

    return (
        <div className="row p-2 min-width-md-parent">
            <span className="fs-5">Usage</span>
            <TableWrapper tableHeaderArr={ThirdPartyTableHeaders} tableBodyArr={tableBodyArr} option={tableOption}/>
        </div>
    )
}

export default ThirdPartyTableSection;
