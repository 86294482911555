import React, {useEffect, useState} from "react";
import ComponentHeader from "../CommonComponents/componentHeader";
import ChartSection from "./chartSection";
import TimeFrameSelector from "./timeFrameSelector";
import BreakdownTable from "./breakdownTable";
import TypeSelector from "./typeSelector";
import {useDispatch, useSelector} from "react-redux";
import {fetchAnalytics} from "../../Actions/analyticsFetchterAction";
import LoadingSpinner from "../CommonComponents/loadingSpinner";
import MultiDropDownComponent from "./multiDropDownComponent";
import {COMPANY_ID_LIST} from "../../Reducers/companyIdListReducer";
import {constructCompanyIdList} from "../../Factory/factory";
import {getCompanyValues} from "../../AnalyticsHelperLib/AnalyticsDataHelper/analyticsDataHelperFunctions";

const ANALYTICS_COMPONENT = "ANALYTICS_COMPONENT";
const AnalyticsWrapper = () => {
    const dispatch = useDispatch();
    const selectedDate = useSelector(state => state.analyticsTimeFrame);
    const selectedType = useSelector(state => state.analyticsType);
    const analyticsData = useSelector(state => state.analyticsData);
    const analyticsSecondaryData = useSelector(state => state.analyticsOrderCount);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if(analyticsData && analyticsSecondaryData) setShowLoading(false);
    },[analyticsData, analyticsSecondaryData])

    useEffect(() => {
        setShowLoading(true);
        dispatch(fetchAnalytics(selectedDate, selectedType));
    }, [selectedDate, selectedType, dispatch]);

    useEffect(()=>{
        return ()=>{
            dispatch({
                type: COMPANY_ID_LIST,
                payload: constructCompanyIdList(getCompanyValues([]))
            });
        }
    },[dispatch])

    return (
        <>
            <ComponentHeader title={"Analytics"}/>
            <div className="mt-4 d-flex flex-column">
                <div className={`analytics-parent d-flex flex-column ${analyticsData === null ? 'disabled-div' : ''}`}>
                    <div className='d-flex flex mb-4'>
                        <MultiDropDownComponent/>
                        <TimeFrameSelector/>
                    </div>
                    <div className='mb-4'>
                        <TypeSelector/>
                    </div>
                    <div className='mb-0'>
                        <ChartSection/>
                    </div>
                    <div className='mb-4'>
                        <BreakdownTable/>
                    </div>
                </div>
                {showLoading && <div>
                    <LoadingSpinner/>
                </div>}
            </div>
        </>
    )
}

export default AnalyticsWrapper;
export {ANALYTICS_COMPONENT};
