import {EMAIL_OR_PASS_MATCHED} from '../Components/Login/loginForm';
import {clearJwtRefreshToken, clearJwtToken, storeJwtRefreshToken, storeJwtToken} from "../Util/localCache";
import {findEnterpriseLoginInfo} from "../Util/queries";
import updateAccessToken from "./authRefreshAction";


const verifyEmailAndPass = (email, pass) =>
    async (dispatch) => {
        try {
            let response = await findEnterpriseLoginInfo({email: email, password: pass});
            if (response?.data?.accessToken) {
                clearJwtToken();
                clearJwtRefreshToken();
                storeJwtToken(response.data.accessToken);
                storeJwtRefreshToken(response.data.refreshToken);
                dispatch({type: EMAIL_OR_PASS_MATCHED, payload: true});
                setTimeout(() => {
                    dispatch(updateAccessToken());
                }, 540000);
            } else {
                dispatch({type: EMAIL_OR_PASS_MATCHED, payload: false});
            }
        } catch (e) {
            console.error(e)
            dispatch({type: EMAIL_OR_PASS_MATCHED, payload: false});
        }
    }


const logout = () => async dispatch => {
    clearJwtToken();
    clearJwtRefreshToken();
    dispatch({type: EMAIL_OR_PASS_MATCHED, payload: null});
    window.location.reload(false);
}

export default verifyEmailAndPass;
export {logout};
