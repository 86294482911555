import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import getMagicLink from "../../Actions/magicLinkAction";
import {MAGIC_LINK} from "../../Reducers/magicLinkReducer";

let linkTracker = "";
const MagicLink = ({selectedAdminId, selectedCompanyId}) => {
    const dispatch = useDispatch();
    const magicLinkObj = useSelector(state => state.magicLink);
    const [showLoading, setShowLoading] = useState(false);

    const handleClick = () => {
        setShowLoading(true);
        dispatch(getMagicLink(selectedAdminId, selectedCompanyId));
    }

    useEffect(() => {
        if (magicLinkObj !== null && linkTracker !== magicLinkObj.getMagicLink()) {
            linkTracker = magicLinkObj.getMagicLink();
            window.open(magicLinkObj.getMagicLink(), '_blank'); //'location=yes, height=570, width=520, scrollbars=yes, status=yes'
        }

        setShowLoading(false);

        return () => {
            dispatch({
                type: MAGIC_LINK,
                payload: null
            })
        }
    }, [magicLinkObj, dispatch]);

    return (
        <div className="downloadBtn tableBtnWithBorder  d-flex align-items-center justify-content-center" onClick={handleClick} title={"Get access to the account"}>
            {showLoading && <div className="spinner-border spinner-border-sm disabledLoading"></div>}
            {!showLoading && <i className="bi bi-box-arrow-up-right"></i>}
        </div>
    )
}

export default MagicLink;
