import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    ACH_ERROR_MODAL_ID,
    ACH_MANDATE_CONFIRMATION_MODAL_ID,
    ACH_SUCCESS_CONFIRMATION_MODAL_ID
} from "./addBankDetails";
import {getACHBankInfo} from "../../Actions/achBankInfoAction";

const MandateModalContent = ({stripe}) => {
    const clientSecret = useSelector(state => state.clientSecret);
    const dispatch = useDispatch();

    const handleMandateSubmit = (ev) => {
        ev.preventDefault();
        document.getElementById(ACH_MANDATE_CONFIRMATION_MODAL_ID + "-close-btn").click();
        stripe.confirmUsBankAccountSetup(clientSecret.getSecret())
            .then(({setupIntent, error}) => {
                if (error) {
                    console.error(error.message);
                    document.getElementById(ACH_ERROR_MODAL_ID).click();
                } else if (setupIntent.status === "requires_payment_method") {
                    document.getElementById(ACH_ERROR_MODAL_ID + "-show-btn").click();
                } else if (setupIntent.status === "succeeded") {
                    dispatch(getACHBankInfo());
                    document.getElementById(ACH_SUCCESS_CONFIRMATION_MODAL_ID + "-show-btn").click()
                } else if (setupIntent.next_action?.type === "verify_with_microdeposits") {
                    // The account needs to be verified via microdeposits.
                    // Display a message to consumer with next steps (consumer waits for
                    // microdeposits, then enters a statement descriptor code on a page sent to them via email).
                }
            });
    }

    return (
        <div>
            <form onSubmit={handleMandateSubmit}>
                <div className="bank-mandate-text">
                    By confirming this mandate, you authorize Shipday to initiate debits from the bank account specified
                    above
                    for
                    any amount owed for charges arising from your use of Shipday's services and/or purchase of products,
                    in
                    accordance with Shipday's website and terms. This authorization will remain in effect until revoked.
                    You
                    may amend or cancel this authorization at any time by providing Shipday with a 30-day notice.
                    <br/><br/>
                    If you use Shipday's services or make periodic purchases as per Shipday's terms, you authorize
                    Shipday
                    to periodically debit your bank account. Payments that fall outside the regular authorized debits
                    will
                    be processed only after obtaining your specific authorization.
                    <br/><br/>
                    Please review the above authorization carefully. By proceeding with the ACH payment, you confirm
                    that
                    you have read, understood, and agreed to the terms and conditions outlined in this mandate modal.
                    <br/><br/>
                    If you have any questions or need further assistance, please contact Shipday's <a href={"mailto::support@shipday.com"}>customer support</a>.
                </div>
                <div>
                    <button data-bs-dismiss="modal" className="cancel-btn mendate-submit-btn">Decline</button>
                    <button type="submit" className="submit-btn mendate-submit-btn">Accept</button>
                </div>
            </form>
        </div>
    )
}

export default MandateModalContent;
