import {getConfigWithAuth} from "./APIReqHelper";
import apiClient from "./apiClient"
import paymentAPIClient from "./paymentAPIClient";

export const getJSON = (url) => {
    return apiClient.get(url, getConfigWithAuth());
}

export const postJSON = (url, data, withAuth = false) => {
    if (withAuth) return apiClient.post(url, data, getConfigWithAuth());
    return apiClient.post(url, data);
}

export const putJSON = (url, data) => {
    return apiClient.put(url, data, getConfigWithAuth())
}

export const paymentPostJSON = (url, data) => {
    return paymentAPIClient.post(url, data, getConfigWithAuth());
}

export const paymentGetJSON = (url) => {
    return paymentAPIClient.get(url, getConfigWithAuth());
}

export const paymentPutJSON = (url, data) => {
    return paymentAPIClient.put(url, data, getConfigWithAuth());
}

export const paymentDelJSON = (url) => {
    return paymentAPIClient.delete(url, getConfigWithAuth());
}