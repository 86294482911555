import React from "react";
import LoginForm from "./loginForm";
import LogoSection from "./logoSection";
import "../../Stylesheets/login.css";

const LoginPageWrapper = () => {
    return (
        <div className="loginBody">
            <LoginForm/>
            <LogoSection/>
        </div>
    )
}

export default LoginPageWrapper;
