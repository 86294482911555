import React from "react";
import logo from "../../Resources/Image/logo.svg";

const LogoSection = () => {
    return (
        <div className="w-100 p-0 d-flex logoSection justify-content-center">
            <div className="align-middle text-center my-auto pb-logo">
                <img alt={"Shipday"} className="loginLogo" src={logo}/>
                <span className="text-white d-flex mt-4 fs-5">
                </span>
            </div>
        </div>
    );
}

export default LogoSection;
