import {GetPartnerCardDetails} from "./partnerCardInfoDetailsAction";
import {removePartnerPaymentCard} from "../Util/queries";

export const RemovePartnerCard = () => async dispatch => {
    try {
        let request = await removePartnerPaymentCard();
        // let request = {status: 200}
        if (request.status === 200 || request.status === 201 || request.status === 204) {
            dispatch(GetPartnerCardDetails());
        } else throw Error(request.statusText);
    } catch (e) {
        console.error(e);
    }
}