import React from "react";
import {countryList} from "../../Util/countryListHelper";

const CountryList = () => {
    const getAllCountryOptions = () => {
        return countryList.map((country) => {
            return <option value={country.countryCode}
                           selected={country.countryCode === "US"}>{country.countryName}</option>;
        })
    }
    return (
        <>
            {getAllCountryOptions()}
        </>
    );
}

export default CountryList;

