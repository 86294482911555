import {ENTERPRISE_ADMIN_DETAILS} from "../Actions/enterpriseUserDetailsAction";

export default (state = null, action) => {
    switch (action.type) {
        case ENTERPRISE_ADMIN_DETAILS:
            return action.payload;
        default:
            return state;
    }
}
