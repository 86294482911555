import React from "react";

const ErrorModalContent = () => {
    return (
        <div className="p-4">
            <div className="fs-4">Ran into an error.</div>
            <div className="sub-text">Please check with your Stripe account, if any information was wrong and try again. If the issue persists, please contact support for further help.</div>
        </div>
    )
}

export default ErrorModalContent;
