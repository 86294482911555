import React from "react";
import TableWrapper from "../Table/tableWrapper";
import {getInvoicesTable} from "../../Util/TableHelpers/invoiceTableHelper";
import {useSelector} from "react-redux";

const PayoutsTableHeader = ["Status", "Date", "Amount USD", " "];
const NoPayoutsText = "You do not have any payouts"

const PayoutsTableSection = () => {
    const option = {
        minWidth: false
    }
    const partnerInvoices = useSelector(state => state.partnerInvoices);
    if (partnerInvoices === null) return <></>

    const tableBodyArr = getInvoicesTable(partnerInvoices.getInvoicesArr());

    return (
        <div className="row mt-4 p-4 border-top">
            <span className="fs-5">Payouts</span>
            <TableWrapper tableHeaderArr={PayoutsTableHeader} tableBodyArr={tableBodyArr} errorText={NoPayoutsText}
                          option={option}/>
        </div>
    )
}

export default PayoutsTableSection;
