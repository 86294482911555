import {constructEnterpriseDetails, constructEnterpriseLevelsDetails} from "../Factory/factory";
import {findEnterpriseDetails} from "../Util/queries";
import {ENTERPRISE_LEVELS_DETAILS} from "../Reducers/enterpriseLevelsDetailsReducer";

const ENTERPRISE_ADMIN_DETAILS = "ENTERPRISE_ADMIN_DETAILS";

export const getPartnerDetails = () =>
    async (dispatch) => {
        try {
            let response = await findEnterpriseDetails();
            if (response.data) {
                let detailsData = response.data;
                detailsData.admin.billingDiscountAmount = detailsData.admin.billingDiscountAmount || 0; //might change later on.
                let enterpriseDetailsObj = constructEnterpriseDetails(detailsData.admin);
                let enterpriseLevelsDetailsObj = constructEnterpriseLevelsDetails(detailsData.enterprise);
                dispatch({
                    type: ENTERPRISE_ADMIN_DETAILS,
                    payload: enterpriseDetailsObj
                })

                dispatch({
                    type: ENTERPRISE_LEVELS_DETAILS,
                    payload: enterpriseLevelsDetailsObj
                })
            } else {
                dispatch({
                    type: ENTERPRISE_ADMIN_DETAILS,
                    payload: null,
                })
            }
        } catch (e) {
            dispatch({
                type: ENTERPRISE_ADMIN_DETAILS,
                payload: null,
            })
        }
    }

export {ENTERPRISE_ADMIN_DETAILS}