import React from "react";
import ComponentHeader from "../CommonComponents/componentHeader";
import ThirdPartyTableSection from "./thirdPartyTableSection";

export const THIRD_PARTY_COMPONENT = "THIRD_PARTY_COMPONENT";

const ThirdPartyBillingWrapper = () => {
    return (
        <>
            <ComponentHeader title={"Third Party Usage"}/>
            <div className="mt-0 m-2 p-3">
                <ThirdPartyTableSection/>
            </div>
        </>
    )
}

export default ThirdPartyBillingWrapper;
