export const ACH_BANK_INFO_REDUCER = "ACH_BANK_INFO_REDUCER";

export default (state = null, action) => {
    switch (action.type) {
        case ACH_BANK_INFO_REDUCER:
            return action.payload;
        default:
            return state;
    }
}
