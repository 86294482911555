const universalChartConfig = {
    type: 'line',
    options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
                text: 'Analytics'
            },
            interaction: {
                intersect: true,
                mode: 'index',
            },
            spanGaps: true,
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                padding: 10
            }
        },
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                border: {
                    display: true
                },
                display: true,
                title: {
                    display: true,
                    text: 'Month'
                },
                grid: {
                    display: true,
                    color: "#F7F9FA"
                }
            },
            y: {
                border: {
                    display: false
                },
                display: true,
                title: {
                    display: false,
                    text: ""
                },
                ticks: {
                    display: false,
                    stepSize: 50
                },
                suggestedMin: 0,
                suggestedMax: 1000,
                grid: {
                    drawBorder: false,
                    display: true,
                    color: "#F7F9FA",
                }
            }
        }
    },
    plugins: [{
        afterDraw: chart => {
            if (chart.tooltip?._active?.length) {
                let x = chart.tooltip._active[0].element.x;
                let yAxis = chart.scales.y;
                let ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'black';
                ctx.stroke();
                ctx.restore();
            }
        }
    }]
}

export default universalChartConfig;
