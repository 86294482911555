import {PARTNER_ACCOUNTS} from "../Actions/enterpriseAccountsAction";

export default (state = null, action) => {
    switch (action.type) {
        case PARTNER_ACCOUNTS:
            return action.payload;
        default:
            return state;
    }
}
