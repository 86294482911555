import universalChartConfig from "./chartUniversalConfig";
import {ANALYTICS_TYPE_TO_TEXT, ANALYTICS_TYPES} from "../AnalyticsDataHelper/analyticsDataHelperFunctions";
import {findRenderedComponentWithType} from "react-dom/test-utils";

const getChartConfig = (data, currentType, currentDate, companyData) => {
    let yMax = 5;
    if (Array.isArray(data)) {
        yMax = findMaxVal(data, currentType);
        universalChartConfig.data = getDataConf(data, currentType, currentDate);
        universalChartConfig.options.plugins.legend.display = false;
    } else {
        universalChartConfig.data = getMultipleDataConf(data, currentType, currentDate, companyData);
        universalChartConfig.options.plugins.legend.display = true;
    }
    universalChartConfig.options.scales.x.title = getTitle(currentType, currentDate);
    universalChartConfig.options.scales.y.suggestedMax = yMax + (yMax * 0.05);
    universalChartConfig.options.scales.y.ticks.stepSize = figureOutStepSize(yMax);
    universalChartConfig.options.plugins.title.display = false;
    universalChartConfig.options.animation = getAnimationConfig();
    return universalChartConfig;
}

const getDataConf = (dataPoints, currentType, currentDate) => {
    let dataConf = {
        labels: getLabelsBasedOnDate(currentDate),
        datasets: [
            {
                label: getTitle(currentType),
                data: dataPoints,
                borderColor: '#2EB487',
                cubicInterpolationMode: 'monotone',
                fill: false,
                tension: 0.4,
                pointRadius: 7,
                backgroundColor: '#edeff166',
            }
        ]
    };
    return dataConf;
}

const getMultipleDataConf = (dataPointsObj, currentType, currentDate, companyData) => {
    let dataConf = {
        labels: getLabelsBasedOnDate(currentDate),
        datasets: []
    };

    dataConf.datasets =Object.keys(dataPointsObj).map((pointID, ind) => {
        let companyName = companyData.companyDetailsObj[pointID].companyName;
        return {
            label: companyName,
            data: dataPointsObj[pointID],
            borderColor: colorForLines(ind),
            cubicInterpolationMode: 'monotone',
            fill: false,
            tension: 0.4,
            pointRadius: 7,
            backgroundColor: '#f3f3f3',
        }
    })
    return dataConf;
}

export const getLineDatasetObj = (prevDataset, idList, dataPointsObj, companyData) => {
    let tracker = {};
    debugger;
    let newDataset = prevDataset.filter(data => {
        if(idList.indexOf(data.companyId) !== -1) {
            tracker[data.companyId] = true;
            return true;
        }
        return false;
    });

     Object.keys(dataPointsObj).forEach((pointID, ind) => {
        if(tracker[idList[ind]]) return;
        let companyName = companyData[idList[ind]].companyName;
        newDataset.push({
            label: companyName,
            data: dataPointsObj[pointID],
            borderColor: colorForLines(ind),
            cubicInterpolationMode: 'monotone',
            fill: false,
            tension: 0.4,
            pointRadius: 7,
            backgroundColor: '#f3f3f3',
        })
     });

     return newDataset;
}

const getLabelsBasedOnDate = (selectedDate = "today") => {
    let labels = [], am = [], pm = [];
    let currentDate = new Date();
    switch (selectedDate) {
        case "today":
        case "yesterday":
            for (let i = 0; i < 12; i++) {
                if (i === 0) {
                    am.push("12 AM");
                    pm.push("12 PM");
                } else {
                    am.push(i + " AM");
                    pm.push(i + " PM");
                }
            }
            labels.push(...am, ...pm);
            return labels;

        case "lastWeek":
        case "thisWeek":
            labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            return labels;

        case "thisMonth":
            for (let i = 1; i <= currentDate.getDate(); i++) {
                labels.push(i);
            }
            return labels;

        case "lastMonth":
            let lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 0).getDate();
            for (let i = 1; i <= lastMonth; i++) {
                labels.push(i);
            }
            return labels;

        default:
            return labels;

    }
}

const findMaxVal = (data, type) => {
    if (type === ANALYTICS_TYPES.ON_TIME_DELIVERIES) return 90;
    let max = 5;
    data.forEach((a) => {
        max = a > max ? a : max;
    })
    return max;
}

const figureOutStepSize = (maxVal) => {
    const minStepVal = 10;
    let rounded = Math.round(maxVal / minStepVal);
    if (rounded <= 1) return 1;
    else if (rounded <= 10) return 10;
    else if (rounded < 30) return 30;
    else if (rounded < 50) return 50;
    else if (rounded < 100) return 100;
    else if (rounded < 500) return 500;
    else if (rounded < 1000) return 1000;
    else {
        let pow = Math.floor(Math.log10(rounded));
        let newRound = Math.ceil(rounded / Math.pow(10, pow));
        return newRound * Math.pow(10, pow);
    }
}

const getTitle = (currentType) => {
    return ANALYTICS_TYPE_TO_TEXT[currentType];
}

const getAnimationConfig = () => {
    return {
        delay: (context) => {
            let delay = 0;
            if (context.type === 'data' && context.mode === 'default') {
                delay = context.dataIndex * 75 + context.datasetIndex * 25;
            }
            return delay;
        }
    }
}

export default getChartConfig;

const colorForLines = (ind) => {
    let colorArray = [
        '#E6B333', '#6666FF', '#999966', '#99FF99', '#B34D4D',
        '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#3366E6', '#FF4D4D', '#99E6E6', '#4DB380'];
    return colorArray[ind%50];
}

