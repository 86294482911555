import React from 'react';
import {useSelector} from "react-redux";
import DashboardSummeryInfoSection from "./dashboardSummeryInfoSection";

const DashboardInfo = () => {
    const partnerInfo = useSelector(state => state.partnerDetails);

    if (partnerInfo === null) return <></>;

    return (
        <div className="d-flex flex-column mt-4">
            <DashboardSummeryInfoSection enterpriseInfo={partnerInfo}/>
        </div>
    )
}

export default DashboardInfo;