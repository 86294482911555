import React from "react";
import TableWrapper from "../Table/tableWrapper";
import {useSelector} from "react-redux";
import {getEarningsTable} from "../../Util/TableHelpers/earningsTableHelper";

const EarningTableHeaders = ["Service Period", "Total Number of Paid Accounts", "Total Usage Billing($)", "Your Earning($)", "Details"];

const EarningsTableSection = () => {
    const tableOption = {
        firstChildHighlight: true
    }
    const partnerDetails = useSelector(state => state.partnerDetails);
    const partnerAccountsObj = useSelector(state => state.partnerAccounts);
    const billingDataObj = useSelector(state => state.partnerBillingData);
    if (partnerDetails === null || partnerAccountsObj === null || billingDataObj === null)
        return (
            <div className="row p-2 min-width-md-parent">
                <span className="fs-5">Earnings</span>
                <TableWrapper tableHeaderArr={EarningTableHeaders} tableBodyArr={[]}
                              errorText="You do not have any earnings available to show"/>
            </div>
        )

    const tableBodyArr = getEarningsTable(billingDataObj.billingFormattedObj, partnerDetails.billingDiscountAmount, partnerAccountsObj)
    EarningTableHeaders[3] = "Your Earning($)" + ` (${partnerDetails.billingDiscountAmount}%)`;

    return (
        <div className="row p-2 min-width-md-parent">
            <span className="fs-5">Earnings</span>
            <TableWrapper tableHeaderArr={EarningTableHeaders} tableBodyArr={tableBodyArr} option={tableOption}/>
        </div>
    )
}

export default EarningsTableSection;
