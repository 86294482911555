import React from "react";
import TimeFrameSingleBtn from "./timeFrameSingleBtn";

const timeFrames = [['today', 'Today'], ['yesterday', 'Yesterday'], ['thisWeek', 'This Week'], ['lastWeek', 'Last Week'], ['thisMonth', 'This Month'], ['lastMonth', 'Last Month']];

const TimeFrameSelector = () => {
    const showMobileView = () => {
        if(window.screen.width < 600) {
            return true;
        }
        return false;
    }

    const getTimeFrameBtns = () => {
        let first = false;
        let last = false;
        return timeFrames.map((val, ind) => {
            first = ind === 0;
            last = ind === timeFrames.length - 1;
            return <TimeFrameSingleBtn key={ind} first={first} last={last} value={val[0]} text={val[1]} mobileView={showMobileView()}/>
        });
    }

    return (
        <div className="container-fluid pe-0 time-selector-btns">
            {/*<div className="display-flex float-left">*/}
            {/*    <div className="btn sd-btn analytics-heatmap-btn sd-secondary-btn"><img className="heatmap-icon"*/}
            {/*                                                                            src="/assets/images/fire.svg"/>*/}
            {/*        <span id="heatmap-btn-text">Show heatmap</span>*/}
            {/*    </div>*/}
            {/*    <div className="analytics-heatmap-btn"><span*/}
            {/*        id="heatmap-overview">Total number of deliveries: N/A</span></div>*/}
            {/*</div>*/}
            <div className="btn-group sd-btn-group float-end bg-light" role="group">
                {getTimeFrameBtns()}
            </div>
        </div>
    )
}

export default TimeFrameSelector;
