import {PARTNER_CREDIT_CARD_INFO} from "../Components/Payments/creditCardInfoSection";
import {constructPartnerCardDetails} from "../Factory/factory";
import {findPartnerPaymentsCard} from "../Util/queries";

let testData = {
    data: {
        cardInfo: {
            last4Digits: "1234",
            expireMonth: "12",
            expireYear: "25"
        }
    }
}

export const GetPartnerCardDetails = () =>
    async (dispatch) => {
        try {
            let response = await findPartnerPaymentsCard();
            // let response = testData;
            if (response.data?.cardInfo) {
                let cardInfo = response.data.cardInfo;
                dispatch({
                    type: PARTNER_CREDIT_CARD_INFO,
                    payload: constructPartnerCardDetails(cardInfo.last4Digits, cardInfo.expireMonth, cardInfo.expireYear)
                })
            } else {
                dispatch({
                    type: PARTNER_CREDIT_CARD_INFO,
                    payload: null
                })
            }
        } catch (e) {
            dispatch({
                type: PARTNER_CREDIT_CARD_INFO,
                payload: null
            })
        }
    }
