import {
    ANALYTICS_TYPES,
    flattenAnalyticsData,
    getCumulativeData,
    getCumulativeOverview,
    getSingleCumulativeData
} from "../AnalyticsHelperLib/AnalyticsDataHelper/analyticsDataHelperFunctions";

export class AnalyticsData {
    constructor(rawData, selectedDate, selectedType) {
        this.rawData = rawData;
        this.selectedDate = selectedDate;
        this.selectedType = selectedType;
        this.formattedCompanyData = flattenAnalyticsData(rawData, selectedType);
        this.cumulativeOverview = getCumulativeOverview(this.formattedCompanyData);
        this.companyWiseCumulativeData = {};
    }

    updateCumulativeData(secondaryDataObj) {
        let totalOrderCount = secondaryDataObj.getOverview(ANALYTICS_TYPES.COMPLETED_DELIVERIES);
        this.cumulativeData = getCumulativeData(this.formattedCompanyData, this.selectedType, this.selectedDate, secondaryDataObj.getFormattedData(), totalOrderCount);
    }

    updateCompanyWiseCumulativeData() {
        if (Object.keys(this.companyWiseCumulativeData).length !== 0) return;
        let _this = this;
        Object.keys(this.formattedCompanyData).forEach(companyID => {
            let cumulativeData = getSingleCumulativeData(_this.formattedCompanyData[companyID], this.selectedType)
            this.companyWiseCumulativeData[companyID] = cumulativeData;
        });
    }

    getCompanyWiseCumulativeOverView(companyIdArr, type) {
        let filteredData = {};
        let _this = this;
        Object.keys(_this.formattedCompanyData).forEach(companyId => {
            if (companyIdArr.indexOf(Number(companyId)) !== -1) {
                filteredData[companyId] = _this.formattedCompanyData[companyId];
            }
        });
        let companyWiseOverview = getCumulativeOverview(filteredData);
        if (type === ANALYTICS_TYPES.COMPLETED_DELIVERIES) return companyWiseOverview[type];
        if (companyWiseOverview[type]) return companyWiseOverview[type] / companyWiseOverview[ANALYTICS_TYPES.COMPLETED_DELIVERIES];
        else return companyWiseOverview[this.typeMapper(type)] / companyWiseOverview[ANALYTICS_TYPES.COMPLETED_DELIVERIES];

        return [this.typeMapper(type)];
    }

    getCompanyWiseCumulativeData(companyIdArr) {
        let filteredObj = {};
        companyIdArr.forEach(companyId => {
            filteredObj[companyId] = this.companyWiseCumulativeData[companyId];
        });
        return filteredObj;
    }

    getOverview(selectedType) {
        if (selectedType === ANALYTICS_TYPES.COMPLETED_DELIVERIES) return this.cumulativeOverview[selectedType];
        if (this.cumulativeOverview[selectedType]) return this.cumulativeOverview[selectedType] / this.cumulativeOverview[ANALYTICS_TYPES.COMPLETED_DELIVERIES];
        else return this.cumulativeOverview[this.typeMapper(selectedType)] / this.cumulativeOverview[ANALYTICS_TYPES.COMPLETED_DELIVERIES];
    }

    typeMapper(selectedType) {
        switch (selectedType) {
            case "pickupToDeliveryTime":
                return "avgPickupToDeliveryTime";
            case "placementToDeliveryTime":
                return "avgPlacementToDeliveryTime";
        }
    }

    getCumulativeData() {
        return this.cumulativeData;
    }

    getFormattedData() {
        return this.formattedCompanyData;
    }
}

export class SelectedCompanyForAnalytics {
    constructor(companyListArr = []) {
        this.companyList = companyListArr;
    }

    getCompanyList() {
        return this.companyList;
    }
}
