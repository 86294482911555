const getDataByMonth = (targetMonth, targetYear, data) => {
    return data.filter((elem) => {
        return checkMonth(elem.startDate, targetMonth, targetYear);
    });
}

const checkMonth = (date, targetMonth, targetYear) => {
    targetMonth = targetMonth + "";
    targetYear = targetYear + "";
    let elemDate = new Date(date);
    let elemMonth = elemDate.getMonth() + "";
    let elemYear = elemDate.getFullYear() + "";
    if (elemMonth == targetMonth && elemYear === targetYear)
        return true;
    return false
}

export default getDataByMonth;

export function calculateAmountAfterDiscount(amount, discount) {
    let payableAmount = (amount - discount * amount / 100);
    return payableAmount.toFixed(2);
}

export function getDiscountAmount(amount, discount) {
    let discountAmount = discount * amount / 100;
    return discountAmount.toFixed(2);
}

export function getUniqueAccountsObj(arr) {
    let accountsObj = {};
    arr.forEach((a) => {
        let {companyId, companyName, companyAddress, email, phone, adminId, ...billingInfo} = a;
        if (accountsObj[companyId] === undefined) {
            accountsObj[companyId] = {companyId, companyName, companyAddress, email, phone, adminId};
            accountsObj[companyId].billingArr = [];
        }
        accountsObj[a.companyId].billingArr.push(billingInfo);
    });
    return accountsObj;
}

export const sortTableByMonth = (prevCom, curCom) => {
    let prevDate = "1 " + prevCom[0];
    let curDate = "1 " + curCom[0]
    return sortByMonth(prevDate, curDate);
}

export const sortByMonth = (prev, cur) => {
    let prevMonth = new Date(prev);
    let curMonth = new Date(cur);
    return curMonth.getTime() - prevMonth.getTime();
}

