import React from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import StripeCard from "./stripeCard";

const stripePromise = loadStripe('pk_live_goDpxfRC7Z3f2CVPmuUsAX6Q00GAYHagBy');

const StripeCardWrapper = ({show, isUpdate}) => {
    return (
        <Elements stripe={stripePromise}>
            <StripeCard show={show} isUpdate={isUpdate}/>
        </Elements>
    )
}

export default StripeCardWrapper;
