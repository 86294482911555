//Date, Company name, delivery fees, tips, third party total, paid, unpaid for csv

import downloadIcon from "../../Resources/Image/sd-download.svg";

const csvRowHeader = ["Date", "Company Name", "Delivery Fees($)", "Tips($)", "Third Party Total($)", "Paid Amount($)", "Outstanding Amount($)"];

export const getThirdPartyUsageCSVDataLink = (fileName, date, weeklyBillingData, accountData) => {
    fileName += ".csv";
    let blob = new Blob([createCSVRows(date, weeklyBillingData, accountData)], {type: "text/csv"});
    let url = window.URL.createObjectURL(blob);
    return <a className="downloadBtn" href={url} download={fileName} title={"Download Details"}><img
        src={downloadIcon}/></a>;
}

const createCSVRows = (date, weeklyBillingData, accountData) => {
    let rowString = csvRowHeader.join(",");
    rowString += "\n";
    weeklyBillingData.map((entry) => {
        let row = [];
        let companyName = accountData[entry.companyId].companyName;
        let delivery = Number(entry.deliveryFeeAmount).toFixed(2);
        let tips = Number(entry.tipsAmount).toFixed(2);
        let total = Number(entry.totalAmount).toFixed(2);
        let paid = Number(entry.paidAmount).toFixed(2);
        let unpaid = Number(entry.unpaidAmount).toFixed(2);
        row.push(date, companyName, delivery, tips, total, paid, unpaid);
        let newRow = row.join(",");
        rowString += newRow + "\r\n";
    })
    return rowString;
}
