import {calculateAmountAfterDiscount, sortTableByMonth} from "../filter";
import {getUsageCSVDataLink} from "./usageCsvDownloadHelper";
import {calculateDiscountAmount, getShortDate} from "./commonHelper";

export const getUsageTable = (monthsBasedData, discount, accountData) => {
    let tableRowsArr = [];
    for (const month in monthsBasedData) {
        let tableCol = [];
        let dateObj = new Date(month);
        tableCol.push(getShortDate(dateObj));
        let totalNumberOfAccountsForTheMonth = countOfAccountForThatMonth(dateObj, accountData.getAccountsArray());
        tableCol.push(totalNumberOfAccountsForTheMonth);
        let totalNumberOfBilledAcc = monthsBasedData[month].billedAccountsCount;
        tableCol.push(totalNumberOfBilledAcc);
        let total = monthsBasedData[month].totalBillingAmount.toFixed(2);
        tableCol.push(total);
        let discountAmount = calculateDiscountAmount(total, discount);
        tableCol.push(discountAmount);
        let amountDue = calculateAmountAfterDiscount(total, discount);
        tableCol.push(amountDue);
        let downloadCSV = getUsageCSVDataLink("details_" + month, monthsBasedData[month].billingInfos, accountData.getAccountsObj(), discount);
        tableCol.push(downloadCSV);
        tableRowsArr.push(tableCol);
    }
    return tableRowsArr.sort(sortTableByMonth);
}

const   countOfAccountForThatMonth = (month, accountData) => {
    let totalAccountCount = 0;
    let targetMonthDateObj = new Date(month);
    accountData.forEach((account) => {
        let accountMemberSinceDateObj = new Date(account.memberSince);
        if (isNaN(accountMemberSinceDateObj.getTime())) totalAccountCount++;
        else if (checkIfCreatedBefore(accountMemberSinceDateObj, targetMonthDateObj)) {
            totalAccountCount++;
        }
    });
    return totalAccountCount;
}

const checkIfCreatedBefore = (toBeCheckedDateObj, targetDateObj) => {
    if (targetDateObj.getFullYear() > toBeCheckedDateObj.getFullYear()) return true;
    else if (targetDateObj.getFullYear() === toBeCheckedDateObj.getFullYear()) {
        if (targetDateObj.getMonth() >= toBeCheckedDateObj.getMonth()) return true;
    }
    return false;
}
