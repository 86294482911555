import React, {useEffect, useRef, useState} from "react";
import AddCreditCardModal from "./addCreditCardModal";
import {useDispatch, useSelector} from "react-redux";
import {RemovePartnerCard} from "../../Actions/partnerCardRemoveAction";
import PaymentSetupWrapper from "../PaymentSetup/paymentSetupWrapper";

const NoCreditCardText = "No credit card information found";
const PARTNER_CREDIT_CARD_INFO = "PARTNER_CREDIT_CARD_INFO";
const PARTNER_CREDIT_UPDATE_CARD_STATE = "PARTNER_CREDIT_CARD_STATE";
const CARD_UPDATE_STATES = {
    pending: "PENDING",
    none: "NONE",
    success: "SUCCESSFUL",
    failed: "FAILED"
}

const CreditCardInfoSection = () => {
    const creditCardObj = useSelector(state => state.partnerCreditCardInfo);
    const partnerDetails = useSelector(state => state.partnerDetails);

    const dispatch = useDispatch();
    const updateRef = useRef(null);
    const removeRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [removing, setRemoving] = useState(false);
    const handleAddCreditCard = () => {
        setShowModal(!showModal);
    }

    const handleRemoveCard = () => {
        setRemoving(true);
        dispatch(RemovePartnerCard());
    }

    useEffect(() => {
        if (creditCardObj === null) {
            setRemoving(false);
            setIsUpdate(false);
        } else setIsUpdate(true);
    }, [creditCardObj])

    const getCreditCardInfo = () => {
        if (creditCardObj === null) return (
            <>
                <div className="text-center">
                    {NoCreditCardText}
                </div>
                <span type="button" className="text-btn" data-bs-toggle="modal"
                      data-bs-target="#creditCardAddModal" onClick={handleAddCreditCard}>Add card</span>
            </>
        )
        else return (
            <>
                <div className="">
                    Ending in <span className="fw-bold">{creditCardObj.last4Digits}</span>
                </div>
                <div className="">
                    Expires in <span className="fw-bold">{creditCardObj.expireMonth}/{creditCardObj.expireYear}</span>
                </div>
                <div className="">
                    {!removing && <>
                        <span type="button" className="mx-2 text-btn text-btn-update" data-bs-toggle="modal"
                              data-bs-target="#creditCardAddModal" ref={updateRef}
                              onClick={handleAddCreditCard}>Update</span>
                        <span type="button" className="mx-2 text-btn text-btn-remove" ref={removeRef}
                              onClick={handleRemoveCard}>Remove</span>
                    </>}
                    {removing &&
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"/>}
                </div>
            </>
        )
    }

    const showACH = () => {
        let address = partnerDetails.billingAddress;
        const usAddressKeywords = [
            'USA',
            'United States',
            'United States of America',
            'US',
            'America',
            'U.S.',
            'U.S.A.'
        ];


        const normalizedAddress = address?.toLowerCase();
        console.log(normalizedAddress)

        return usAddressKeywords.some(keyword =>
            normalizedAddress && normalizedAddress.includes(keyword?.toLowerCase())
        );
    }
    // const showACH = () => {
    //     return true;
    // }

    return (
        <div className="row mt-2 paymentMethod">
            <div className="container-fluid sd-shadow">
                <div className="m-3 mx-5 d-flex fw-light credit-card-info-holder">
                    <div className="">
                        <span className="fw-bold">Credit Card</span>
                    </div>
                    {getCreditCardInfo()}
                </div>
                {showACH() && <PaymentSetupWrapper/>}
                <AddCreditCardModal isUpdate={isUpdate} show={showModal} setShow={setShowModal}/>
            </div>
        </div>
    )
}

export default CreditCardInfoSection;
export {PARTNER_CREDIT_CARD_INFO, PARTNER_CREDIT_UPDATE_CARD_STATE, CARD_UPDATE_STATES};

