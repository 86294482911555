import React, {useRef} from "react";
import {
    ACH_ERROR_MODAL_ID,
    ACH_MANDATE_CONFIRMATION_MODAL_ID,
    ACH_SUCCESS_CONFIRMATION_MODAL_ID
} from "./addBankDetails";
import {useDispatch, useSelector} from "react-redux";

const BankInfoAddModalContent = ({stripe}) => {
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const mandateBtnRef = useRef(null);
    const successBtnRef = useRef(null);
    const errorBtnRef = useRef(null);
    const clientSecret = useSelector(state => state.clientSecret);

    const handleSubmit = (ev) => {
        //@TODO handle client secret is yet to be fetched state or had some issue state.
        ev.preventDefault();
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        document.getElementById('achBankInfoModal-close-btn').click();
        ev.target.reset();
        console.log("Initiating Bank account setup with stripe "+clientSecret.getSecret());
        //@TODO need to set the client secret
        //mandateBtnRef.current.click();
        stripe.collectBankAccountForSetup({
            clientSecret: clientSecret.getSecret(),
            params: {
                payment_method_type: 'us_bank_account',
                payment_method_data: {
                    billing_details: {
                        name: name,
                        email: email,
                    },
                },
            },
            expand: ['payment_method'],
        })
            .then(({setupIntent, error}) => {
                if (error) {
                    console.error(error.message);
                    document.getElementById(ACH_ERROR_MODAL_ID + "-show-btn").click();
                    // PaymentMethod collection failed for some reason.
                } else if (setupIntent.status === 'requires_payment_method') {
                    // Customer canceled the hosted verification modal. Present them with other
                    // payment method type options.
                } else if (setupIntent.status === 'requires_confirmation') {
                    // We collected an account - possibly instantly verified, but possibly
                    // manually-entered. Display payment method details and mandate text
                    // to the customer and confirm the intent once they accept
                    // the mandate.
                    //confirmationForm.show();
                    mandateBtnRef.current.click();
                }
            });
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="bank-input">
                    <label className='form-label'>Account Holder Name</label>
                    <input ref={nameRef} type='text' className='form-control' id='bankInfoUserName'
                           placeholder="John Doe" required={true}></input>
                </div>
                <div className="bank-input">
                    <label className='form-label'>Email Address</label>
                    <input ref={emailRef} type='email' className='form-control' id='bankInfoUserEmail'
                           placeholder="john@gmail.com" required={true}></input>
                </div>
                <button type={"submit"} className="submit-btn">Proceed to next step</button>
                <button ref={mandateBtnRef} id={ACH_MANDATE_CONFIRMATION_MODAL_ID + "-show-btn"} className={"d-none"} data-bs-toggle="modal" data-bs-target={`#${ACH_MANDATE_CONFIRMATION_MODAL_ID}`}>Show mandate</button>
                <button ref={successBtnRef} id={ACH_SUCCESS_CONFIRMATION_MODAL_ID + "-show-btn"} className={"d-none"} data-bs-toggle="modal" data-bs-target={`#${ACH_SUCCESS_CONFIRMATION_MODAL_ID}`}>Show success</button>
                <button ref={errorBtnRef} id={ACH_ERROR_MODAL_ID + "-show-btn"} className={"d-none"} data-bs-toggle="modal" data-bs-target={`#${ACH_ERROR_MODAL_ID}`}>Show Error</button>
            </form>
        </div>
    )
}

export default BankInfoAddModalContent;
