import {LEFT_SIDE_BAR_SELECTED} from "../Components/EnterprisePortal/leftNavbar";
import {constructSelectedComponent} from "../Factory/factory"

export default (state = constructSelectedComponent(), action) => {
    switch (action.type) {
        case LEFT_SIDE_BAR_SELECTED:
            return action.payload;
        default:
            return state;
    }
}
