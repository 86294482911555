import React, {useEffect, useState} from "react";
import TableWrapper from "../Table/tableWrapper";
import {useSelector} from "react-redux";
import {generateAnalyticsTable} from "../../Util/TableHelpers/analyticsTableHelper";

let breakdownTableHeaders = ['Name', '# of completed deliveries', 'Avg. pickup to delivery time', '% On-time deliveries', 'Avg. placement to delivery time'];

const BreakdownTable = () => {
    const analyticsData = useSelector(state => state.analyticsData);
    const selectedType = useSelector(state => state.analyticsType);
    const enterpriseAccountObj = useSelector(state => state.partnerAccounts);
    const [tableArr, setTableArr] = useState([])

    useEffect(() => {
        if (analyticsData && enterpriseAccountObj) {
            setTableArr(generateAnalyticsTable(enterpriseAccountObj.getAccountsObj(), analyticsData.getFormattedData()));
        } else {
            setTableArr([]);
        }
    }, [analyticsData]);

    return (
        <div className="row min-width-md-parent">
            <span className="sub-title">Breakdown by Companies</span>
            <TableWrapper tableHeaderArr={breakdownTableHeaders} tableBodyArr={tableArr} option={{firstRowWidth: 30}}/>
        </div>
    )
}

export default BreakdownTable;
