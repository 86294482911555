import React, {useEffect, useRef, useState} from "react";
import Selectable from "./selectable";


const DropDownWrapper = ({listArr = [], onChange, selectedCompanyList}) => {
    const aggregatedRef = useRef(null);
    const searchRef = useRef(null);
    const [searched, setSearched] = useState('');
    const addVal = (val) => {
        aggregatedRef.current.childNodes[0].checked = false;
        let newArr = selectedCompanyList;
        if (newArr.indexOf(val) === -1) newArr.push(val);
        onChange(selectedCompanyList)
    }

    const removeVal = (val) => {
        let removedValInd = selectedCompanyList.indexOf(val);
        let newArr = selectedCompanyList;
        if (removedValInd !== -1) newArr = selectedCompanyList.splice(removedValInd, 1);
        onChange(selectedCompanyList);
        if (selectedCompanyList.length === 0) aggregatedRef.current.childNodes[0].checked = true;
    }

    const getAllSelectables = () => {
        let filteredArr = listArr;

        if (searched.length !== 0) {
            filteredArr = filteredArr.filter(val => {
                return val.label.toLowerCase().includes(searched);
            })
        }

        return filteredArr.map((list, ind) => {
            let previouslyClicked = selectedCompanyList.indexOf(list.value) === -1 ? false : true;
            return <Selectable key={ind} dataObj={list} addVal={addVal} removeVal={removeVal} arr={selectedCompanyList}
                               initiallyClicked={previouslyClicked}/>
        })
    }

    const cumulativeSelected = () => {
        onChange([]);
    }

    const handleSearch = () => {
        let searchedVal = searchRef.current.value;
        setSearched(searchedVal);
    }

    return (
        <div className='position-absolute bg-white border shadow p-2 mt-1 multi-dropdown-section'>
            <div className='pt-1 pb-2'>
                <input ref={searchRef} type="search" className="form-control px-2 py-1" placeholder="Search by store name"
                       aria-label="Search by store name" onChange={handleSearch}/>

            </div>
            <div ref={aggregatedRef} className="form-check py-1">
                <input className="form-check-input" type="checkbox" value="" id={"multi-checkbox-aggregated"}
                       checked={selectedCompanyList.length === 0} onChange={cumulativeSelected}/>
                <label className="form-check-label w-100 cur-pointer" htmlFor={"multi-checkbox-aggregated"}>
                    Aggregate for all stores
                </label>
            </div>
            {getAllSelectables()}
        </div>
    )
}

export default DropDownWrapper;
