import React from "react";
import {useSelector} from "react-redux";
import DashboardInfoCard from "./dashboardInfoCard";
import {getTotalDueAndSavingForCurrentMonth, getTotalSavingForCurrentYear} from "../../Util/dataHelper";
import {PARTNER_TYPE_AFFILIATE} from "../EnterprisePortal/leftNavbar";

const DashboardSummeryInfoSection = ({enterpriseInfo}) => {
    const billingDataObj = useSelector(state => state.partnerBillingData);
    const partnerAccountsObj = useSelector(state => state.partnerAccounts);
    let pairs, earningsPair, discountThisYear = 0;
    if (enterpriseInfo === null) {
        return <></>
    }

    if (billingDataObj == null || partnerAccountsObj == null) {
        pairs = [["Active Accounts", 0], ["Paid Accounts", 0], ["Current Month Due", "$" + 0]];
        earningsPair = [["Active Accounts", 0], ["Paid Accounts", 0], ["Earned This Month", "$" + 0]];
    } else {
        const {
            afterDiscountAmount,
            discountedAmount
        } = getTotalDueAndSavingForCurrentMonth(billingDataObj.billingFormattedObj, enterpriseInfo.billingDiscountAmount);
        discountThisYear = getTotalSavingForCurrentYear(billingDataObj.billingFormattedObj, enterpriseInfo.billingDiscountAmount)
        pairs = [["Active Accounts", partnerAccountsObj.getTotalCountOfActiveAccounts()], ["Paid Accounts", partnerAccountsObj.getTotalCountOfPaidAccounts()], ["Current Month Due", "$" + afterDiscountAmount]];
        earningsPair = [["Active Accounts", partnerAccountsObj.getTotalCountOfActiveAccounts()], ["Paid Accounts", partnerAccountsObj.getTotalCountOfPaidAccounts()], ["Earned This Month", "$" + discountedAmount]];

    }

    let targetPair;
    targetPair = pairs;
    if (enterpriseInfo.partnerType === PARTNER_TYPE_AFFILIATE) targetPair = earningsPair;

    const getInfoCards = () => {
        return targetPair.map((pair, ind) => {
            let goToDetails = false;
            if (ind > 1) goToDetails = true;
            return (
                <div key={ind} className="col align-self-center justify-content-center">
                    <DashboardInfoCard details={pair[0]} number={pair[1]} goToDetails={goToDetails}
                                       partnerType={enterpriseInfo.partnerType}/>
                </div>
            )
        })
    }

    return (
        <div className="row g-0 card-container">
            {getInfoCards()}
        </div>
    )
}

export default DashboardSummeryInfoSection;
