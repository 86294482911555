import {findAccessToken} from "../Util/queries";
import {
    clearJwtRefreshToken,
    clearJwtToken,
    getJwtRefreshToken,
    storeJwtRefreshToken,
    storeJwtToken
} from "../Util/localCache";

let tokenUpdateTimer = 9 * 60000;
let timer = null;

const updateAccessToken = () => async dispatch => {
    try {
        let response = await findAccessToken(getJwtRefreshToken());

        if (response?.data?.accessToken) {
            clearJwtToken();
            clearJwtRefreshToken();
            storeJwtToken(response.data.accessToken);
            storeJwtRefreshToken(response.data.refreshToken);
        }

        clearTimeout(timer);

        timer = setTimeout(() => {
            dispatch(updateAccessToken());
        }, tokenUpdateTimer);

    } catch (e) {
        setTimeout(() => {
            updateAccessToken();
        }, 15000);
    }
}

export default updateAccessToken;
