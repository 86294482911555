import React from "react";
import AddBankDetails from "./addBankDetails";
import BankDetails from "./bankDetails";
import {useSelector} from "react-redux";

export const PAYMENT_SETUP = "PAYMENT_SETUP";

const PaymentSetupWrapper = () => {
    const bankInfo = useSelector(state => state.achBankInfo)


    const getComponents = () => {
        if (bankInfo !== null) return <BankDetails/>
        return <AddBankDetails/>
    }


    return (
        <>
            <div className="p-3 px-5 fw-light bank-info-container d-flex">
                <span className="fw-bold no-wrap">ACH Payment</span>
                {getComponents()}
            </div>
        </>
    )
}

export default PaymentSetupWrapper;
