import React from "react";
import UsageTableSection from "./usageTableSection";
import ComponentHeader from "../CommonComponents/componentHeader";

const BILLING_COMPONENT = "BILLING_COMPONENT";

const BillingPageWrapper = () => {
    return (
        <>
            <ComponentHeader title={"Billing & Usage"}/>
            <div className="mt-0">
                <UsageTableSection/>
            </div>
        </>
    )
}

export default BillingPageWrapper;
export {BILLING_COMPONENT};
