import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import EnterprisePortalWrapper from "./Components/EnterprisePortal/enterprisePortalWrapper";
import ProtectedRoute from "./protectedRoute";
import LoginPageWrapper from "./Components/Login/loginPageWrapper";
import "./Stylesheets/fonts.css";
import "./Stylesheets/common.css"
import SignUpPageWrapper from "./Components/SignUp/signUpPageWrapper";

function App() {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route exact path={["/login", "/"]} component={LoginPageWrapper}/>
                    <ProtectedRoute exact path="/enterprise-portal" component={EnterprisePortalWrapper}/>
                    <Route exact path="/signup" component={SignUpPageWrapper}></Route>
                    <Route path="*">
                        <Redirect to="/login"/>
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;
