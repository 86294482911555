import React, {useEffect} from "react";
import SignUpForm from "./signUpForm";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const SignUpPageWrapper = () => {
    const passMatched = useSelector(state => state.passMatched);
    const history = useHistory();

    useEffect(() => {
        if (passMatched)
            history.push("/partner-portal");
    }, [passMatched])
    return (
        <div className="container-fluid">
            <div className="d-flex flex-column justify-content-center">
                <div className="fw-bold fs-2 mt-4 pt-5 p-3 text-center">
                    Sign up as affiliate partner
                </div>
                <SignUpForm/>
            </div>
        </div>
    )
}

export default SignUpPageWrapper;
