import React from "react";
// import InvoicesTableSection from "./invoicesTableSection";
import CreditCardInfoSection from "./creditCardInfoSection";
import ComponentHeader from "../CommonComponents/componentHeader";

const PAYMENT_COMPONENT = "PAYMENT_COMPONENT";

const PaymentsWrapper = () => {


    return (
        <>
            <ComponentHeader title="Payments"/>
            <div className="mt-0">
                <div className="row p-2 ps-0">
                    <span className="sub-title">Payment method</span>
                    <CreditCardInfoSection/>
                    {/*<InvoicesTableSection/>*/}
                </div>
            </div>
        </>
    )
}

export default PaymentsWrapper;
export {PAYMENT_COMPONENT};

