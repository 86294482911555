import React from "react";
import {useSelector} from "react-redux";
import TableWrapper from "../Table/tableWrapper";
import {getDashboardAccountsTableData} from "../../Util/TableHelpers/accountsTableHelper";

const AccountsHeaderArr = ["", "Business Name", "Admin Name", "Admin Email", "Phone Number", "Current Plan", "Country"]
const NoAccountsText = "You do not have any accounts";

const AccountsTableSection = () => {
    const partnerAccountObj = useSelector(state => state.partnerAccounts);
    if (partnerAccountObj === null)
        return <></>

    const tableBodyArr = getDashboardAccountsTableData(partnerAccountObj.getAccountsArray());
    return (
        <div className="row mt-5 min-width-md-parent">
            <TableWrapper tableHeaderArr={AccountsHeaderArr} tableBodyArr={tableBodyArr} errorText={NoAccountsText}/>
        </div>
    )
}

export default AccountsTableSection;
