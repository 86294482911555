import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import newUserSignUp, {SIGN_UP_STATE} from "../../Actions/signupAction";
import CountryList from "./countryList";

const SignUpForm = () => {
    const [pending, setPending] = useState(false);
    const [passMatched, setPassMatched] = useState(null);
    const signupState = useSelector(state => state.signingUpState);
    const compNameRef = useRef(null);
    const addrRef = useRef(null);
    const phoneNumRef = useRef(null);
    const emailAddrRef = useRef(null);
    const contPersonRef = useRef(null);
    const contPersonTitleRef = useRef(null);
    const billingAddrRef = useRef(null);
    const countryRef = useRef(null);
    const passRef = useRef(null);
    const passConfirmRef = useRef(null);
    const dispatch = useDispatch();
    const handleFormSubmit = (e) => {
        e.preventDefault();
        let companyName = compNameRef.current.value;
        let address = addrRef.current.value;
        let phoneNumber = phoneNumRef.current.value;
        let emailAddress = emailAddrRef.current.value;
        let contactPerson = contPersonRef.current.value;
        let contactPersonTitle = contPersonTitleRef.current.value;
        let billingAddress = billingAddrRef.current.value;
        let password = passRef.current.value;
        let countryCode = countryRef.current.value;
        setPending(true);
        dispatch({
            type: SIGN_UP_STATE,
            payload: null
        })
        dispatch(newUserSignUp(companyName, phoneNumber, emailAddress, address, contactPerson, contactPersonTitle, billingAddress, password, countryCode))
    }

    const passMatchCheck = () => {
        let pass1 = passRef.current.value;
        let pass2 = passConfirmRef.current.value;
        if (pass1 === null || pass1 === undefined || pass1.length === 0) setPassMatched(null);
        else if (pass2 === null || pass2 === undefined || pass2.length === 0) setPassMatched(null);
        else if (pass1 !== pass2) setPassMatched(false);
        else setPassMatched(true);
    }

    useEffect(() => {
        setPending(false);
    }, [signupState])

    return (
        <form onSubmit={handleFormSubmit} className="signup-form d-flex flex-column justify-content-center">
            <div className="section border rounded-3 my-4 py-4 px-5 shadow-sm">
                <div className="py-3">
                    <label className="form-label">
                        Company Name
                    </label>
                    <input ref={compNameRef} className="form-control" placeholder="Enter your company name"
                           required={true}></input>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Address
                    </label>
                    <input ref={addrRef} className="form-control" placeholder="Enter your address"
                           required={true}></input>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Phone Number
                    </label>
                    <input ref={phoneNumRef} className="form-control" placeholder="Enter your phone number"
                           required={true}></input>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Email
                    </label>
                    <input ref={emailAddrRef} className="form-control" placeholder="Enter your email address"
                           required={true}></input>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Contact Person Name
                    </label>
                    <input ref={contPersonRef} className="form-control"
                           placeholder="Enter the name of the contact person"
                           required={true}></input>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Title of the Contact Person
                    </label>
                    <input ref={contPersonTitleRef} className="form-control"
                           placeholder="Enter the title of the contact person" required={true}></input>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Billing Address
                    </label>
                    <input ref={billingAddrRef} className="form-control" placeholder="Enter the billing address"
                           required={true}></input>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Country (Available in selected countries now)
                    </label>
                    <select ref={countryRef} className="form-select" required={true}>
                        <CountryList/>
                    </select>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Set Password
                    </label>
                    <input type="password" ref={passRef} className="form-control" placeholder="Set new password"
                           required={true} onChange={passMatchCheck}></input>
                </div>
                <div className="pb-3">
                    <label className="form-label">
                        Confirm Password
                    </label>
                    <input type="password" ref={passConfirmRef} className="form-control"
                           placeholder="Confirm new password"
                           required={true} onChange={passMatchCheck}></input>
                </div>
                {passMatched != null && !passMatched &&
                <div className="passMismatchWarning text-danger fw-light text-center">Pass did not match</div>}
            </div>
            <button type="submit" className="btn btn-success p-2 my-4 shadow" disabled={pending || !passMatched}>
                {pending && (
                    <div className="spinner-border spinner-border-sm me-2" role="status"/>
                )}
                Sign Up
            </button>
            {signupState === false && <div className="alert alert-danger text-center" role="alert">
                <span className="fw-bold">Could not Sign up.</span><br/> User already exists. Please try with a
                different email.
            </div>}
            <span className="fw-light fs-6 text-end">**All the fields are required</span>
        </form>
    )
}

export default SignUpForm;
