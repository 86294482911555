import {PARTNER_PROFILE_UPDATE_STATE} from "../Actions/passwordUpdateAction";

export default (state = null, action) => {
    switch (action.type) {
        case PARTNER_PROFILE_UPDATE_STATE:
            return action.payload;
        default:
            return state;
    }
}
