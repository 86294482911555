import React from "react";

const TableBody = ({tableBodyArr, option, errorText = "No Data available"}) => {

    const getTableRowCol = (colsArr) => {
        return colsArr.map((col, ind) => {
            return (
                <td key={ind}>{col}</td>
            )
        })
    }

    const getTableBodyRows = () => {
        if (tableBodyArr === null || tableBodyArr.length === 0) {
            return (
                <tr>
                    <td colSpan="100" className="text-center border-top">{errorText}</td>
                </tr>
            );
        }

        return tableBodyArr.map((row, ind) => {
            let classNames = "border-top";
            if (option?.firstChildHighlight && ind === 0) {
                classNames += " fw-bold";
            }
            return (
                <tr key={ind} className={classNames}>
                    {getTableRowCol(row)}
                </tr>
            )
        });
    }

    return (
        <tbody>
        {getTableBodyRows()}
        </tbody>
    )
}

export default TableBody;
