export const COMPANY_ID_LIST = 'COMPANY_ID_LIST';

export default (state = null, action) => {
    switch (action.type) {
        case COMPANY_ID_LIST:
            return action.payload;
        default:
            return state;
    }
}
