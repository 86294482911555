import {typeMapper} from "../../Util/TableHelpers/analyticsTableHelper";

export function flattenAnalyticsData(rawData, selectedType) {
    try {
        let companyInfo = {};
        let regionId, subregionId;
        rawData.regions.forEach(region => {
            regionId = region.regionId;
            region.subRegions.forEach(subregion => {
                subregionId = subregion.subRegionId;
                subregion.companies.forEach(company => {
                    company.subRegionId = subregionId;
                    company.regionId = regionId;
                    companyInfo[company.companyId] = company;
                })
            })
        })
        return companyInfo;
    } catch (e) {
        console.error(e);
        return {};
    }
}

export function getCumulativeData(formattedData, selectedType, selectedDate, secondaryData) {
    try {
        if (formattedData == undefined || selectedType == undefined || selectedDate == undefined || secondaryData == undefined)
            return [];

        let timeWiseData = {};
        let timeWiseSecondaryData = {};
        Object.keys(formattedData).forEach(companyId => {
            let companyData = formattedData[companyId];
            companyData.breakdownByTime.forEach((timeData, ind) => {
                if (timeWiseData[timeData[typeWisePropName(selectedDate)]] === undefined) {
                    timeWiseData[timeData[typeWisePropName(selectedDate)]] = 0;
                    timeWiseSecondaryData[timeData[typeWisePropName(selectedDate)]] = 0;
                }

                if (timeData[typeMapper(selectedType)] > 0) {
                    if (selectedType === ANALYTICS_TYPES.COMPLETED_DELIVERIES) timeWiseData[timeData[typeWisePropName(selectedDate)]] += timeData[typeMapper(selectedType)];
                    else {
                        timeWiseData[timeData[typeWisePropName(selectedDate)]] += (timeData[typeMapper(selectedType)] * secondaryData[companyId].breakdownByTime[ind][ANALYTICS_TYPES.COMPLETED_DELIVERIES]);
                        timeWiseSecondaryData[timeData[typeWisePropName(selectedDate)]] += secondaryData[companyId].breakdownByTime[ind][ANALYTICS_TYPES.COMPLETED_DELIVERIES];
                    }
                }
            })
        })

        if (selectedType !== ANALYTICS_TYPES.COMPLETED_DELIVERIES) timeWiseData = weightedAvgCalc(timeWiseData, timeWiseSecondaryData);

        return Object.keys(timeWiseData).sort((a, b) => a - b).map(key => timeWiseData[key]);
    } catch (e) {
        console.error(e);
        return []
    }
}

export function getSingleCumulativeData(companyData,selectedType) {
    let data = companyData.breakdownByTime.map(d => {
        return d[typeMapper(selectedType)];
    })
    return data;
}

export function getCumulativeOverview(formattedData) {
    let overview = {};
    Object.keys(formattedData).forEach(companyId => {
        let companyOverview = formattedData[companyId].overview;
        Object.keys(companyOverview).forEach(type => {
            if (overview[type] === undefined) overview[type] = 0;
            if (companyOverview[type] === -1 || isNaN(companyOverview[type])) return;
            if (type === ANALYTICS_TYPES.COMPLETED_DELIVERIES) {
                overview[type] += Number(companyOverview[type]);
            } else
                overview[type] += Number(companyOverview[type] * companyOverview[ANALYTICS_TYPES.COMPLETED_DELIVERIES]);
        })
    })

    return overview;
}

function weightedAvgCalc(targetObj, orderObj) {
    Object.keys(targetObj).forEach(date => {
        if (orderObj[date] > 0)
            targetObj[date] = targetObj[date] / orderObj[date];
    })
    return targetObj;
}

function typeWisePropName(selectedDate) {
    const HOUR = "hour";
    const DATE = "date";
    if (selectedDate === 'today' || selectedDate === 'yesterday') return HOUR;
    else return DATE;
}

export const ANALYTICS_TYPES = {
    COMPLETED_DELIVERIES: 'completedDeliveries',
    AVG_PICKUP_TO_DELIVERIES: 'pickupToDeliveryTime',
    ON_TIME_DELIVERIES: 'onTimeDeliveries',
    AVG_PLACEMENT_TO_DELIVERY: 'placementToDeliveryTime'
}

export const ANALYTICS_TYPE_TO_TEXT = {
    'completedDeliveries': '# of completed deliveries',
    'pickupToDeliveryTime': 'Avg. pickup to delivery time',
    'onTimeDeliveries': '% On-time deliveries',
    'placementToDeliveryTime': 'Avg. placement to delivery time'
}

export const getCompanyValues = (valuesObjArr) => {
    return valuesObjArr.map(val => val.value);
}

export const getCompanyIdNamePair = (arr) => {
    return arr.map(val => {
        return {label: val.companyName, value: val.companyId}
    });
}

