import {findAnalyticsDetails} from "../Util/queries";
import {constructAnalyticsDetails} from "../Factory/factory";
import {ANALYTICS_DATA} from "../Reducers/analyticsDataReducer";
import {COMPLETED_ORDER_COUNT_DATA} from "../Reducers/analyticsOrderCountDataReducer";
import {ANALYTICS_TYPES} from "../AnalyticsHelperLib/AnalyticsDataHelper/analyticsDataHelperFunctions";

export const fetchAnalytics = (selectedDate, selectedType, secondary = false) => async dispatch => {
    try {
        let response = await findAnalyticsDetails(selectedDate, selectedType);
        let responseSecondary = await findAnalyticsDetails(selectedDate, ANALYTICS_TYPES.COMPLETED_DELIVERIES);
        if (response.data && responseSecondary.data) {
            let analyticsData = constructAnalyticsDetails(response.data, selectedDate, selectedType);
            let analyticsSecondaryData = constructAnalyticsDetails(responseSecondary.data, selectedDate, ANALYTICS_TYPES.COMPLETED_DELIVERIES);

            dispatch({
                type: COMPLETED_ORDER_COUNT_DATA,
                payload: analyticsSecondaryData
            });

            dispatch({
                type: ANALYTICS_DATA,
                payload: analyticsData
            });
        }
    } catch (e) {
        dispatch({
            type: ANALYTICS_DATA,
            payload: null
        })
    }
}
