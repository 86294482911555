export const ANALYTICS_DATA = "ANALYTICS_DATA";

export default (state = null, action) => {
    switch (action.type) {
        case ANALYTICS_DATA:
            return action.payload;
        default:
            return state;
    }
}
