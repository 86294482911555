import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

const TableWrapper = ({tableHeaderArr, tableBodyArr, errorText, option}) => {
    let minWidthClass = "min-width-md";
    if (!option?.minWidth) minWidthClass = "";

    if (tableHeaderArr.length === 0 || tableBodyArr.length === 0) {
        return (
            <div className={minWidthClass}>
                <table className="table table-v2 table-borderless table-responsive text-start">
                    <TableHeader tableHeaderArr={tableHeaderArr} option={option}/>
                    <TableBody tableBodyArr={[]} option={option} errorText={errorText}/>
                </table>
            </div>
        )
    }

    return (
        <div className={minWidthClass}>
            <table className="table table-v2 table-borderless table-responsive text-start">
                <TableHeader tableHeaderArr={tableHeaderArr} option={option}/>
                <TableBody tableBodyArr={tableBodyArr} option={option}/>
            </table>
        </div>
    )
}

export default TableWrapper;
