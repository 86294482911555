const LOGIN_API = "/partner/login";
const BILLING_API = "/partner/billing";
const DETAILS_API = "/partner/details";
const INIT_ACH = "/enterprise/ach/init";

const getLoginUrl = () => {
    return LOGIN_API;
}

const getPortalDataReqUrl = () => {
    return BILLING_API;
}

const getPartnerDetailsReqUrl = () => {
    return DETAILS_API;
}

const getAchInitReqUrl = () => {
    return INIT_ACH;
}

export {getLoginUrl, getPortalDataReqUrl, getPartnerDetailsReqUrl, getAchInitReqUrl};
