export const getShortDate = (dateObj) => {
    if (dateObj) {
        let options = {year: 'numeric', month: 'short'};
        return dateObj.toLocaleString("en-US", options);
    }
}

export const getFullDate = (dateObj) => {
    if (typeof dateObj === "string") dateObj = new Date(dateObj);
    if (isNaN(dateObj.getTime())) {
        return "N/A";
    }

    dateObj = new Date( dateObj.getTime() - dateObj.getTimezoneOffset() * -60000 );

    if (dateObj) {
        let options = {year: 'numeric', month: 'short', day: "numeric"};
        return dateObj.toLocaleString("en-US", options);
    }
}

export const calculateDiscountAmount = (amount, discount) => {
    let discountAmount = (amount * discount) / 100;
    return discountAmount.toFixed(2)
}