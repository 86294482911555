import {initACH} from "../Util/queries";
import {constructClientSecret} from "../Factory/factory";
import {CLIENT_SECRET} from "../Reducers/clientSecretReducer";

export const getClientSecret = () => async dispatch => {
    try {
        const response = await initACH();
        const clientSecretObj = constructClientSecret(response.data.clientSecret);
        dispatch({
            type: CLIENT_SECRET,
            payload: clientSecretObj
        });
    } catch (e) {
        console.error(e);
    }
}
