import React from "react";

const TableHeader = ({tableHeaderArr, option}) => {
    const getHeaders = () => {
        return tableHeaderArr.map((key, ind) => {
            if(option?.firstRowWidth && ind === 0) {
                return <th key={ind} scope="col" width={option?.firstRowWidth + "%"}>{key}</th>
            }
            return <th key={ind} scope="col">{key}</th>
        })
    }
    return (
        <thead>
        <tr>
            {getHeaders()}
        </tr>
        </thead>
    )
}

export default TableHeader;
