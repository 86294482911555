const JWT_TOKEN = "JWT_TOKEN";
const JWT_REFRESH_TOKEN = "JWT_REFRESH_TOKEN";

const storeJwtToken = token => {
    localStorage.setItem(JWT_TOKEN, token);
}

const getJwtToken = () => {
    return localStorage.getItem(JWT_TOKEN);
}

const storeJwtRefreshToken = token => {
    localStorage.setItem(JWT_REFRESH_TOKEN, token);
}

const getJwtRefreshToken = () => {
    return localStorage.getItem(JWT_REFRESH_TOKEN);
}

const clearJwtToken = () => {
    localStorage.removeItem(JWT_TOKEN);
}

const clearJwtRefreshToken = () => {
    localStorage.removeItem(JWT_REFRESH_TOKEN);
}

export {getJwtToken, storeJwtToken, clearJwtToken, storeJwtRefreshToken, clearJwtRefreshToken, getJwtRefreshToken};
