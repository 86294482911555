import {getThirdPartyUsageCSVDataLink} from "./thirdPartyUsageCSVDownloadHelper";

export const getThirdPartyUsageTable = (weekBasedData, accountData) => {
    //Date, Company name, delivery fees, tips, third party total, paid, unpaid for csv
    // date, total billing, outstanding, details
    let tableRowArr = [];
    for (const week in weekBasedData) {
        let tableCol = [];
        let weekLastDate = getLastWeekDate(week);
        tableCol.push(weekLastDate);
        tableCol.push(weekBasedData[week].total);
        //tableCol.push(weekBasedData[week].totalPaid);
        tableCol.push(weekBasedData[week].outstandingAmount);
        let fileName = "thirdParty_details_" + weekLastDate;
        let downloadCSV = getThirdPartyUsageCSVDataLink(fileName, weekLastDate,
            weekBasedData[week].billingInfos, accountData);
        tableCol.push(downloadCSV);
        tableRowArr.push(tableCol);
    }
    return tableRowArr.sort(sortByWeek);
}

const sortByWeek = (week1, week2) => {
    try {
        let w1 = new Date(week1[0]);
        let w2 = new Date(week2[0]);
        return w2.getTime() - w1.getTime();
    } catch (e) {
        return;
    }
}

const getLastWeekDate = (weekStr) => {
    let lastWeekStr = weekStr.split(' - ')[1];
    let [year, month, date] = lastWeekStr.split('-');
    return date + "/" + month + "/" + year;
}
