import {PARTNER_BILLING} from "../Actions/enterpriseBillingDetailsAction";


export default (state = null, action) => {
    switch (action.type) {
        case PARTNER_BILLING:
            return action.payload;
        default:
            return state;
    }
}
