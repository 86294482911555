import React, {useEffect, useRef, useState} from "react";
import {Chart, registerables} from "chart.js";
import getChartConfig, {getLineDatasetObj} from "../../AnalyticsHelperLib/ChartHelpers/chartHelperFunctions";
import {useSelector} from "react-redux";

Chart.register(...registerables);


let chartObj = null;
let companyListTracker = {};
const ChartSection = () => {
    const chartRef = useRef(null);
    const [dataArr, setDataArr] = useState([]);
    const analyticsData = useSelector(state => state.analyticsData);
    const selectedDate = useSelector(state => state.analyticsTimeFrame);
    const selectedType = useSelector(state => state.analyticsType);
    const analyticsSecondaryData = useSelector(state => state.analyticsOrderCount);
    const companyIdList = useSelector(state => state.companyIdList);
    const enterpriseAccounts = useSelector(state => state.partnerAccounts);
    let localConf = null;

    useEffect(() => {
        if (analyticsData && analyticsSecondaryData) {
            analyticsData.updateCumulativeData(analyticsSecondaryData);
            analyticsData.updateCompanyWiseCumulativeData(analyticsSecondaryData);
            if (chartObj !== null) chartObj.destroy();
            if (companyIdList === null || companyIdList.getCompanyList().length === 0) localConf = getChartConfig(analyticsData.getCumulativeData(), selectedType, selectedDate);
            else {
                let companyDetailsObj = enterpriseAccounts.getAccountsObj();
                let idList = companyIdList.getCompanyList();
                localConf = getChartConfig(analyticsData.getCompanyWiseCumulativeData(idList), selectedType, selectedDate, {companyDetailsObj, idList});
                if (idList.length > 1) localConf.options.animation = false;
            }
            chartObj = new Chart(chartRef.current, localConf);

            return () => {
                chartObj.destroy();
            }
        }
    }, [analyticsData, analyticsSecondaryData, chartRef.current, companyIdList]);

    return (
        <canvas className="analytics-chart mb-4" id={"analytics-chart"} ref={chartRef}></canvas>
    )
}

export default ChartSection;