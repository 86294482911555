import {constructEnterpriseDetails} from "../Factory/factory";
import {ENTERPRISE_ADMIN_DETAILS} from "./enterpriseUserDetailsAction";

const PARTNER_PROFILE_UPDATE_STATE = "PARTNER_PROFILE_UPDATE_STATE";
const PROFILE_UPDATE_SUCCESSFUL = "PROFILE_UPDATE_SUCCESSFUL";
const PROFILE_UPDATE_FAILED = "PROFILE_UPDATE_FAILED";

let testData = {
    data: {
        "partnerId": 4084,
        "companyName": "Shipday Trial",
        "address": "7100 STEVENSON BOULEVARD, FREMONT, CA 94538, UNITED STATES",
        "phoneNumber": "+14152752387",
        "email": "info@shipday.com",
        "contactPerson": "Moin Islam",
        "titleOfContactPerson": "CEO",
        "billingAddress": "7100 STEVENSON BOULEVARD, FREMONT, CA 94538, UNITED STATES",
        "partnerCode": "59de2f868e",
        "partnerType": "RESELLER",
        "routingNumber": "12",
        "accountHolderName": "test",
        "accountNumber": "12",
        "paypal": "",
        "billingDiscountPercent": 20,
        "countryCode": null,
        "invitationLink": "https://dispatch.shipday.com/signUp/26kl9m535l"
    }
}

export const updateProfileInfo = (data) => async (dispatch) => {
    try {
        //let response = await updatePartnerPassword(data);
        let response = testData;
        if (response.data) {
            let detailsData = response.data;
            let partnerDetailsObj = constructEnterpriseDetails(detailsData.id, detailsData.partnerType, detailsData.address, detailsData.billingAddress, detailsData.billingDiscountPercent, detailsData.companyName, detailsData.contactPerson, detailsData.contactPersonTitle, detailsData.email, detailsData.phoneNumber, detailsData.secret);
            dispatch({
                type: ENTERPRISE_ADMIN_DETAILS,
                payload: partnerDetailsObj
            })
            dispatch({
                type: PARTNER_PROFILE_UPDATE_STATE,
                payload: PROFILE_UPDATE_SUCCESSFUL
            })
        } else {
            dispatch({
                type: PARTNER_PROFILE_UPDATE_STATE,
                payload: PROFILE_UPDATE_FAILED
            })
        }
    } catch (e) {
        console.error(e);
        dispatch({
            type: PARTNER_PROFILE_UPDATE_STATE,
            payload: PROFILE_UPDATE_FAILED
        })
    }
}

export {PARTNER_PROFILE_UPDATE_STATE, PROFILE_UPDATE_SUCCESSFUL, PROFILE_UPDATE_FAILED}
