import React, {useEffect, useRef, useState} from "react";
import {UpdatePartnerBankInfo} from "../../Actions/partnerBankInfoUpdateAction";
import {useDispatch, useSelector} from "react-redux";
import {CARD_UPDATE_STATES, PARTNER_CREDIT_UPDATE_CARD_STATE} from "../Payments/creditCardInfoSection";

const PARTNER_BANK_INFO = "PARTNER_BANK_INFO";

const BankInfoHolder = () => {
    const bankInfo = useSelector(state => state.partnerBankInfo);
    const updateState = useSelector(state => state.partnerCreditCardUpdateState);
    const dispatch = useDispatch();
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailed, setUpdateFailed] = useState(false);
    const nameRef = useRef(null);
    const routingRef = useRef(null);
    const accRef = useRef(null);
    const paypalRef = useRef(null);

    const updateBankInfo = (ev) => {
        ev.preventDefault();
        let accHolderName = nameRef.current.value;
        let routingNumber = routingRef.current.value;
        let accNumber = accRef.current.value;
        let paypalID = paypalRef.current.value;
        dispatch({
            type: PARTNER_CREDIT_UPDATE_CARD_STATE,
            payload: CARD_UPDATE_STATES.pending
        })
        dispatch(UpdatePartnerBankInfo(routingNumber, accHolderName, accNumber, paypalID));
    }

    useEffect(() => {
        if (bankInfo) {
            nameRef.current.value = bankInfo.name;
            routingRef.current.value = bankInfo.routing;
            accRef.current.value = bankInfo.acc;
            paypalRef.current.value = bankInfo.paypal;
        }
    }, bankInfo)

    useEffect(() => {
        if (updateState === CARD_UPDATE_STATES.success) {
            setUpdateSuccess(true);
            setTimeout(() => {
                setUpdateSuccess(false);
                dispatch({
                    type: PARTNER_CREDIT_UPDATE_CARD_STATE,
                    payload: CARD_UPDATE_STATES.none
                })
            }, 5000);
        } else if (updateState === CARD_UPDATE_STATES.failed) {
            setUpdateFailed(true);
            setTimeout(() => {
                setUpdateFailed(false);
                dispatch({
                    type: PARTNER_CREDIT_UPDATE_CARD_STATE,
                    payload: CARD_UPDATE_STATES.none
                })
            }, 5000);
        }
    }, [updateState])

    return (
        <div className="accordion accordion-flush rounded border shadow-sm mt-2 lh-lg" id="accordionBankUpdate">
            <div className="accordion-item">
                <h2 className="row accordion-header" id="headingBankUpdate">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseBankUpdate" aria-expanded="false"
                            aria-controls="collapseBankUpdate">
                        Update Banking Information
                    </button>
                </h2>
                <div id="collapseBankUpdate" className="accordion-collapse collapse show"
                     aria-labelledby="headingBankUpdate"
                     data-bs-parent="#accordionBankUpdate">
                    <form onSubmit={updateBankInfo}>
                        <div className="row justify-content-center m-1 pt-3">
                            <div className="col text-end pe-2 bankInfoInputLabel">
                                <label className="form-label ">Account Holder Name:</label>
                            </div>
                            <div className="col">
                                <input ref={nameRef} className="form-control bankInfoInput" type="text"
                                       placeholder="Enter Your Name" required={true}/>
                            </div>
                        </div>
                        <div className="row justify-content-center m-1">
                            <div className="col text-end pe-2 bankInfoInputLabel">
                                <label className="form-label ">
                                    Routing Number:
                                </label>
                            </div>
                            <div className="col">
                                <input ref={routingRef} className="form-control bankInfoInput" type="text"
                                       placeholder="Enter Routing Number" required={true}/>
                            </div>
                        </div>
                        <div className="row justify-content-center m-1">
                            <div className="col text-end pe-2 bankInfoInputLabel">
                                <label className="form-label ">
                                    Account Number:
                                </label>
                            </div>
                            <div className="col">
                                <input ref={accRef} className="form-control bankInfoInput" type="text"
                                       placeholder="Enter Bank A/C Number" required={true}/>
                            </div>
                        </div>
                        <div className="row justify-content-center m-1">
                            <div className="col text-end pe-2 bankInfoInputLabel">
                                <label className="form-label ">
                                    Paypal ID:
                                </label>
                            </div>
                            <div className="col">
                                <input ref={paypalRef} className="form-control bankInfoInput" type="text"
                                       placeholder="Enter Paypal ID (Optional)"/>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center p-3">
                            <button type="submit" className="btn bankInfoInput passUpdateBtn"
                                    disabled={updateState === CARD_UPDATE_STATES.pending}>
                                {updateState === CARD_UPDATE_STATES.pending &&
                                <span className="spinner-border spinner-border-sm me-2" role="status"
                                      aria-hidden="true"/>}
                                <span>Update</span>
                            </button>
                        </div>
                    </form>
                    {updateSuccess && <div className="alert alert-success text-center" role="alert">
                        Bank Information Updated
                    </div>}
                    {updateFailed && <div className="alert alert-danger text-center" role="alert">
                        Bank Information Update Failed
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default BankInfoHolder;
export {PARTNER_BANK_INFO};
