import React from "react";

const SuccessModalContent = () => {
    return (
        <div className="p-4">
            <div>
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="64" height="64" rx="32" fill="#17C289" fillOpacity="0.11"/>
                    <path d="M42.6666 24L28 38.6667L21.3333 32" stroke="#17C289" strokeWidth="2"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className="comp-title pt-3">Bank Information Successfully Added</div>
            <div className="btn submit-btn success-btn mt-4 border px-5" data-bs-dismiss="modal">Done</div>
        </div>
    )
}

export default SuccessModalContent;
