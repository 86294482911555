import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    BILLING_UPDATE_FAILED,
    BILLING_UPDATE_SUCCESSFUL,
    updateBillingInformation
} from "../../Actions/billingInfoUpdateAction";

const BillingInformationUpdate = () => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(false);
    const formRef = useRef(null);
    const partnerProfileUpdateState = useSelector(state => state.partnerProfileInfoUpdateState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (partnerProfileUpdateState === BILLING_UPDATE_SUCCESSFUL) {
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
            }, 5000)
        } else if (partnerProfileUpdateState === BILLING_UPDATE_FAILED) {
            setShowFailed(true);
            setTimeout(() => {
                setShowSuccess(false);
            }, 5000)
        }
    }, [partnerProfileUpdateState]);

    const handleFormSubmit = (ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.target);
        const formDataObject = {};

        for (let [key, value] of formData.entries()) {
            formDataObject[key] = value;
        }

        dispatch(updateBillingInformation(formDataObject));
        formRef.current.reset();
    }


    return (
        <div className="accordion info-container p-0  mt-5 lh-lg" id="accordionBillingInfoUpdate">
            <div className="accordion-item border-0 billing-info-update-acc">
                <h2 className="accordion-header" id="headingBillingInfoUpdate">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseBillingInfoUpdate" aria-expanded="false"
                            aria-controls="collapseBillingInfoUpdate">
                        Update Billing Information
                    </button>
                </h2>
                <div id="collapseBillingInfoUpdate" className="accordion-collapse collapse"
                     aria-labelledby="headingBillingInfoUpdate"
                     data-bs-parent="#accordionBillingInfoUpdate">
                    {showSuccess && <div className="alert alert-success text-center m-3" role="alert">
                        Billing Information Updated.
                    </div>}
                    {showFailed && <div className="alert alert-danger text-center m-3" role="alert">
                        Billing Information Update Failed!
                    </div>}
                    <div className="accordion-body passUpdateBody mx-4 p-4">
                        <form ref={formRef} onSubmit={handleFormSubmit}>
                            <div className="m-2 mt-3">
                                <label className="form-label">Name:</label>
                                <input className="form-control" placeholder="Enter your name" name="name"
                                       required={true}></input>
                            </div>

                            <div className="d-flex w-100">
                                <div className="my-2 me-4 mt-3 w-100">
                                    <label className="form-label">Phone:</label>
                                    <input className="form-control" placeholder="Enter your phone number" name="phone"
                                           required={true}></input>
                                </div>

                                <div className="my-2 mt-3 w-100">
                                    <label className="form-label">Country:</label>
                                    <input className="form-control" placeholder="Enter your country name" name="country"
                                           required={true}></input>
                                </div>
                            </div>

                            <div className="my-2 mt-3">
                                <label className="form-label">Address 1:</label>
                                <input className="form-control" placeholder="Enter your address 1" name="addressLineOne"
                                       required={true}></input>
                            </div>

                            <div className="my-2 mt-3">
                                <label className="form-label">Address 2:</label>
                                <input className="form-control" placeholder="Enter your address 2" name="addressLineTwo"
                                       required={true}></input>
                            </div>

                            <div className="my-2 mt-4 text-end">
                                <button type="submit" className="btn passUpdateBtn px-3">Update Information</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillingInformationUpdate;
