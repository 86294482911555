export const countryList = [{"countryName": "Australia", "countryCode": "AU"},
    {"countryName": "Austria", "countryCode": "AT"},
    {"countryName": "Belgium", "countryCode": "BE"},
    {"countryName": "Brazil", "countryCode": "BR"},
    {"countryName": "Bulgaria", "countryCode": "BG"},
    {"countryName": "Canada", "countryCode": "CA"},
    {"countryName": "Cyprus", "countryCode": "CY"},
    {"countryName": "Czech Republic", "countryCode": "CZ"},
    {"countryName": "Denmark", "countryCode": "DK"},
    {"countryName": "Estonia", "countryCode": "EE"},
    {"countryName": "Finland", "countryCode": "FI"},
    {"countryName": "France", "countryCode": "FR"},
    {"countryName": "Germany", "countryCode": "DE"},
    {"countryName": "Greece", "countryCode": "GR"},
    {"countryName": "Hong Kong", "countryCode": "HK"},
    {"countryName": "Hungary", "countryCode": "HU"},
    {"countryName": "India", "countryCode": "IN"},
    {"countryName": "Ireland", "countryCode": "IE"},
    {"countryName": "Italy", "countryCode": "IT"},
    {"countryName": "Japan", "countryCode": "JP"},
    {"countryName": "Latvia", "countryCode": "LV"},
    {"countryName": "Lithuania", "countryCode": "LT"},
    {"countryName": "Luxembourg", "countryCode": "LU"},
    {"countryName": "Malaysia", "countryCode": "MY"},
    {"countryName": "Malta", "countryCode": "MT"},
    {"countryName": "Mexico", "countryCode": "MX"},
    {"countryName": "Netherlands", "countryCode": "NL"},
    {"countryName": "New Zealand", "countryCode": "NZ"},
    {"countryName": "Norway", "countryCode": "NO"},
    {"countryName": "Poland", "countryCode": "PL"},
    {"countryName": "Portugal", "countryCode": "PT"},
    {"countryName": "Romania", "countryCode": "RO"},
    {"countryName": "Singapore", "countryCode": "SG"},
    {"countryName": "Slovakia", "countryCode": "SK"},
    {"countryName": "Slovenia", "countryCode": "SI"},
    {"countryName": "Spain", "countryCode": "ES"},
    {"countryName": "Sweden", "countryCode": "SE"},
    {"countryName": "Switzerland", "countryCode": "CH"},
    {"countryName": "United Arab Emirates", "countryCode": "AE"},
    {"countryName": "United Kingdom", "countryCode": "UK"},
    {"countryName": "United States", "countryCode": "US"}]
